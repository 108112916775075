import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import RedditIcon from '@material-ui/icons/Reddit';
import { Link } from "react-router-dom";

const styles = () => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '110px',
        width: '100%',
        justifyContent: 'center',
        textShadow: '2px 2px 4px #264d66',
    },
    providers: {
        fontSize: 'small',
        textAlign: 'left',
        padding: '5px',
    },
    spacer: {
        borderLeft: '2px solid #ffffffaa',
        marginLeft: '10px',
        paddingLeft: '10px',
        height: '70px',
    },
    socialDiv: {
        textAlign: 'center',
        display: 'inline-block',
    },
    quicklinks: {
        color: 'white',
        textDecoration: 'none',
        fontSize: 'small',
    },
    copyright: { fontFamily: 'Audiowide' },
    '@media screen and (max-width: 930px) and (min-width: 700px)': {
        wrapper: {
          marginLeft: '250px',
        },
    },
    '@media screen and (max-width: 700px)': {
        wrapper: {
            backgroundColor: '#51ade5bb'
        },
        copyright: { fontSize: 'small' },
    },
});

const Footer = withStyles(styles)((props) => {
    const { classes } = props;
    return (
        <div className='footer'>
            <div className={classes.wrapper}>
                <div className={classes.socialDiv}>
                    <a className={classes.quicklinks} target='blank' href='https://twitter.com/1beattheodds/'><TwitterIcon/> </a>
                    <a className={classes.quicklinks} target='blank' href='https://www.facebook.com/1BeatTheOdds/'><FacebookIcon/> </a>
                    <a className={classes.quicklinks} target='blank' href='https://www.instagram.com/1beattheodds/'><InstagramIcon/> </a>
                    <a className={classes.quicklinks} target='blank' href='https://www.reddit.com/user/beattheodds_'><RedditIcon/></a>
                    <div className={classes.copyright}>
                        &#169; BEAT THE ODDS
                    </div>
                    <div>
                        <Link className={classes.quicklinks} to="/privacy">
                            Privacy Policy
                        </Link>
                        <span> &middot; </span>
                        <Link className={classes.quicklinks} to="/about">
                            About
                        </Link>
                    </div>
                </div>
                <div className={classes.spacer}/>
                <div className={classes.providers}>
                    Stats providers:<br/>
                    NFL.com &middot; Pro Football Reference<br/>
                    NBA.com &middot; Basketball Reference<br/>
                    MLB.com &middot; Baseball Reference<br/>
                    FanGraphs &middot; RotoWire
                </div>
            </div>
        </div>
    );
})

export default Footer;