import React from "react";
import {
    FormGroup,
    FormLabel,
    TextField
} from '@material-ui/core';

const roundToTens = (value) => {
    return Math.round(value * 10) / 10;
}

const appendRank = (value, data, prop) => {
    // console.log(value, data, prop)
    if (!data.columnData.label.includes("2020")) {
        const oppStats = data.rowData[data.rowData.length - 1];
        return `${Math.round(value * 10) / 10} (${oppStats.team[prop]})`;
    } else {
        const oppStats = data.rowData[data.rowData.length - 2],
            stat = oppStats.team[data.columnData.name];
        // console.log(data, value, oppStats.team[data.columnData.name])
        return `${Math.round(stat * 10) / 10} (${oppStats.team[prop]})`;
    }
}

const convertToPercent = (value) => {
    return `${Math.round(value * 100)}%`;
}

const colorize = (value, cutoff) => {
    return value >= cutoff ? (
        <div style = {{ color:'green'}}>
            {value}
        </div>
    ) : (
        <div style = {{ color:'red'}}>
            {value}
        </div>
    ) ;
}

const renderOdds = (value) => {
    return value && (
        <div>
            {colorize(value.over, -110, 100)}
            {colorize(value.under, -110, 100)}
        </div>
    );
}

const sortOdds = (order) => (a, b) => {
    return (a.data.over < b.data.over ? -1: 1 ) * (order === 'desc' ? 1 : -1);
}

const sortFraction = (order) => (a, b) => {
    const aValue = a.data.split("/")[1] === "0" ? 0 : a.data.split("/").reduce((n, d, i) => n / (i ? d : 1)),
        bValue = b.data.split("/")[1] === "0" ? 0 : b.data.split("/").reduce((n, d, i) => n / (i ? d : 1));

    if (aValue === bValue && aValue === 0) {
        console.log(aValue, bValue)
        return (a.data.split("/")[1] > b.data.split("/")[1] ? -1: 1 ) * (order === 'desc' ? 1 : -1);
    }
    return (aValue < bValue ? -1: 1 ) * (order === 'desc' ? 1 : -1);
}

const customFilter = (label) => {
    return {
    filterType: 'custom',        
    customFilterListOptions: {
        render: v => {
        if (v[0]) {
            return `Min ${label}: ${v[0]}`;
        } else if (v[1]) {
            return `Max ${label}: ${v[1]}`;
        }
        return [];
        },
        update: (filterList, filterPos, index) => {

        if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
        } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
        } else if (filterPos === -1) {
            filterList[index] = [];
        }

        return filterList;
        },
    },
    filterOptions: {
        names: [],
        logic(age, filters) {
            if (filters[0] && filters[1]) {
                return age < filters[0] || age > filters[1];
            } else if (filters[0]) {
                return age < filters[0];
            } else if (filters[1]) {
                return age > filters[1];
            }
            return false;
        },
        display: (filterList, onChange, index, column) => (
        <div>
            <FormLabel>{label}</FormLabel>
            <FormGroup row>
            <TextField
                label='min'
                value={filterList[index][0] || ''}
                onChange={event => {
                filterList[index][0] = event.target.value;
                onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%' }}
            />
            <TextField
                label='max'
                value={filterList[index][1] || ''}
                onChange={event => {
                filterList[index][1] = event.target.value;
                onChange(filterList[index], index, column);
                }}
                style={{ width: '45%' }}
            />
            </FormGroup>
        </div>
        )
    }
}
}

const commonColumnsPre = [
    // { name: "playerName", label: "Name", options: { filter: false }},
    // { name: "oppRank", label: "Opp Rank", options: { filter: false }},
    { name: "pos", label: "Pos", options: { filterType: 'multiselect' }},
    { name: "opp", label: "Opp", options: { filterType: 'multiselect' }},
    { name: "matchup", label: "Matchup", options: { filterType: 'multiselect' }},
    // { name: "+-", label: "+-", options: { customBodyRender: roundToTens }},
    { name: "percentDiff", label: "% Diff", options: { customBodyRender: convertToPercent, ...customFilter('% Diff') }},
    // { name: "proj+-", label: "Proj. +-", options: { customBodyRender: roundToTens }},
    // { name: "projPercentDiff", label: "Proj. % Diff", options: { customBodyRender: convertToPercent, ...customFilter('Proj % Diff') }},
    { name: "O/U", label: "O/U"},
]

const commonColumnsPost = [
    { name: "gamesOver", label: "Games Over", options: { sortCompare: sortFraction }},
    { name: "btoScore", label: "BTOscore" },
    { name: "lastYr_gamesOver", label: "Last Yr Games Over", options: {  sortCompare: sortFraction, display: false }},
    { name: "dkOdds", label: "DK Odds", options: { customBodyRender: renderOdds, sortCompare: sortOdds }},
    { name: "fdOdds", label: "FD Odds", options: { display: false, customBodyRender: renderOdds, sortCompare: sortOdds }},
    { name: "oppStats2020", options: { display: false }},
    { name: "oppStats", options: { display: false }},
]

export const rushAtt = [
    ...commonColumnsPre,
    { name: "rush/G", label: "Rush Att/G", options: { customBodyRender: roundToTens }},
    { name: 'rushYds/G', label: 'Rush Yds/G', options: { customBodyRender: roundToTens }},
    { name: 'oppRushAtt/G', label: 'Opp Rush Att/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppRushAttRank') }},
    { name: 'oppRushYds/G', label: 'Opp Rush Yds/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppRushYdsRank') }},
    ...commonColumnsPost
]

export const rushYds = [
    ...commonColumnsPre,
    { name: "rushYds/G", label: "Rush Yards/G", options: { customBodyRender: roundToTens }},
    { name: "rushAvg", label: "Rush Avg", options: { customBodyRender: roundToTens }},
    { name: "rush/G", label: "Rush Att/G", options: { customBodyRender: roundToTens }},
    { name: 'oppRushAtt/G', label: 'Opp Rush Att/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppRushAttRank') }},
    { name: 'oppRushYds/G', label: 'Opp Rush Yds/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppRushYdsRank') }},
    ...commonColumnsPost
]

export const passComp = [
    ...commonColumnsPre,
    { name: "passComp/G", label: "Comp/G", options: { customBodyRender: roundToTens }},
    // { name: "projpassComp", label: "Proj. Cmp" },
    // { name: "passYds/G", label: "Pass Yards/G", options: { customBodyRender: roundToTens }},
    // { name: "passAvg", label: "Pass Avg", options: { customBodyRender: roundToTens }},
    { name: 'oppPassAtt/G', label: 'Opp Pass Att/G' , options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassAttRank') }},
    { name: 'oppPassCmp/G', label: 'Opp Pass Cmp/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassCmpRank') } },
    { name: 'oppPassYds/G', label: 'Opp Pass Yds/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassYdsRank') } },
    // { name: "badPassPct", label: "Bad Pass %", options: { customBodyRender: roundToTens }},
    // { name: "droppedRate", label: "Dropped Rate", options: { customBodyRender: roundToTens }},
    ...commonColumnsPost
]

export const passYds = [
    ...commonColumnsPre,
    { name: "passYds/G", label: "Pass Yards/G", options: { customBodyRender: roundToTens }},
    // { name: "projpassYds", label: "Proj. Yds" },
    { name: "passComp/G", label: "Pass Comp/G", options: { customBodyRender: roundToTens }},
    { name: "passAvg", label: "Pass Avg", options: { display: false, customBodyRender: roundToTens }},
    { name: 'oppPassAtt/G', label: 'Opp Pass Att/G' , options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassAttRank') }},
    { name: 'oppPassCmp/G', label: 'Opp Pass Cmp/G', options: { display: false, customBodyRender: (val, data) => appendRank(val, data, 'oppPassCmpRank') } },
    { name: 'oppPassYds/G', label: 'Opp Pass Yds/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassYdsRank') } },
    ...commonColumnsPost
]

export const passAtt = [
    ...commonColumnsPre,
    { name: "passAtt/G", label: "Pass Att/G", options: { customBodyRender: roundToTens }},
    // { name: "projpassAtt", label: "Proj. Att" },
    // { name: "passAvg", label: "Pass Avg", options: { customBodyRender: roundToTens }},
    { name: 'oppPassAtt/G', label: 'Opp Pass Att/G' , options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassAttRank') }},
    { name: 'oppPassCmp/G', label: 'Opp Pass Cmp/G', options: { display: false, customBodyRender: (val, data) => appendRank(val, data, 'oppPassCmpRank') } },
    { name: 'oppPassYds/G', label: 'Opp Pass Yds/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassYdsRank') } },
    ...commonColumnsPost
]

export const passInt = [
    ...commonColumnsPre,
    { name: "passInt/G", label: "Int/G", options: { customBodyRender: roundToTens }},
    // { name: "projpassInt", label: "Proj. Int" },
    { name: 'oppPassInt/G', label: 'Opp Int/G' , options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassAttRank') }},
    ...commonColumnsPost
]

export const rec = [
    ...commonColumnsPre,
    { name: "rec/G", label: "Rec/G", options: { customBodyRender: roundToTens }},
    { name: "tgt/G", label: "Tgt/G", options: { customBodyRender: roundToTens }},
    { name: "catchRate", label: "Catch Rate", options: { display: false ,customBodyRender: roundToTens }},
    { name: "targetShare", label: "Target Share", options: { customBodyRender: roundToTens }},
    { name: "targets", label: "Targets", options: { display: false, customBodyRender: roundToTens }},
    { name: 'oppRec/G', label: 'Opp Rec/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassCmpRank') } },
    { name: 'oppRecYds/G', label: 'Opp Rec Yds/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassYdsRank') } },
    // { name: "rec", label: "Receptions", options: { customBodyRender: roundToTens }},
    ...commonColumnsPost
]

export const recYds = [
    ...commonColumnsPre,
    { name: "recY/G", label: "Rec Yards/G", options: { customBodyRender: roundToTens }},
    { name: "rec/G", label: "Rec/G", options: { customBodyRender: roundToTens }},
    { name: "tgt/G", label: "Tgt/G", options: { customBodyRender: roundToTens }},
    { name: "targetShare", label: "Target Share", options: { customBodyRender: roundToTens }},
    { name: "catchRate", label: "Catch Rate", options: { display: false, customBodyRender: roundToTens }},
    { name: 'oppRec/G', label: 'Opp Rec/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassCmpRank') } },
    { name: 'oppRecYds/G', label: 'Opp Rec Yds/G', options: { customBodyRender: (val, data) => appendRank(val, data, 'oppPassYdsRank') } },
    // { name: "games", label: "G", options: { customBodyRender: roundToTens }},
    // { name: "rec", label: "Receptions", options: { customBodyRender: roundToTens }},
    // { name: "targets", label: "Targets", options: { customBodyRender: roundToTens }},
    ...commonColumnsPost
]

const commonFuturesPost = [
    { name: "O/U", label: "DK OU"},
    { name: "fdOU", label: "FD OU"},
    { name: "diff", label: "Diff" },
    { name: "diffPerG", label: "Diff/GP", options: { customBodyRender: roundToTens }},
    { name: "seasonsOver", label: "Seasons Over", options: { sortCompare: sortFraction }},
    { name: "dkOdds", label: "DK Odds", options: { customBodyRender: renderOdds, sortCompare: sortOdds }},
    { name: "fdOdds", label: "FD Odds", options: { customBodyRender: renderOdds, sortCompare: sortOdds }},
];

export const passYds_futures = [
    { name: "games", label: "GP" },
    { name: "passAvg", label: "Pass Avg" },
    { name: "passPct", label: "Pass Pct" },
    { name: "passYds", label: "Pass Yds" },
    ...commonFuturesPost,
]

export const passTD_futures = [
    { name: "games", label: "GP" },
    { name: "passAtt", label: "Pass Att" },
    { name: "passTD", label: "Pass TDs" },
    ...commonFuturesPost,
]

export const passInt_futures = [
    { name: "games", label: "GP" },
    { name: "sackRate", label: "Sack Rate" },
    { name: "passAtt", label: "Pass Att" },
    { name: "passInt", label: "Pass INT" },
    ...commonFuturesPost,
]

export const rushYds_futures = [
    { name: "games", label: "GP" },
    { name: "rushAtt", label: "Rush Att" },
    { name: "rushYds", label: "Rush Yds" },
    ...commonFuturesPost,
]

export const rushTD_futures = [
    { name: "games", label: "GP" },
    { name: "rushAtt", label: "Rush Att" },
    { name: "rushTD", label: "Rush TD" },
    ...commonFuturesPost,
]

export const recYds_futures = [
    { name: "games", label: "GP" },
    { name: "rec", label: "Rec" },
    { name: "airYardShare", label: "AirYds Share" },
    { name: "recYds", label: "Rec Yds" },
    ...commonFuturesPost,
]

export const rec_futures = [
    { name: "games", label: "GP" },
    { name: "recYds", label: "Rec Yds" },
    { name: "catchRate", label: "Catch Rate" },
    { name: "dropRate", label: "Drop Rate" },
    { name: "targetShare", label: "Target Share" },
    { name: "rec", label: "Rec" },
    ...commonFuturesPost,
]

export const recTD_futures = [
    { name: "games", label: "GP" },
    { name: "rec", label: "Rec" },
    { name: "recTD", label: "Rec TD" },
    ...commonFuturesPost,
]