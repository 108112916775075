import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/storage';

var firebaseConfig = {
    apiKey: "AIzaSyAuIkITrRuFvedw0_tEnJStmacM5FhbRpA",
    authDomain: "beat-odds.firebaseapp.com",
    projectId: "beat-odds",
    storageBucket: "beat-odds.appspot.com",
    messagingSenderId: "305703210917",
    appId: "1:305703210917:web:ff9ec78f5bd13a4d55e24c",
    measurementId: "G-CBLT00YS52"
};

// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default fire;