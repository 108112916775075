import React from "react";
import MainAppBar from './component/menu';
import Footer from './component/footer';
import { Helmet } from 'react-helmet';

const Page = (props) => {
  const defaultDescr = "Discover and analyze NFL, NBA, and MLB bettings odds all in one place! Compare different sportsbooks - get the best lines from DraftKings vs FanDuel to find the best bets available.";
  // useEffect(() => {
  //   document.title = props.title || "";
  // }, [props.title]);

  return (
    <>
      <Helmet>
        <title>{props.title || "Beat The Odds"}</title>
        <meta name="description" content={props.descr || defaultDescr} />
      </Helmet>
      <div style={{ background: props.bgColor, paddingBottom: props.padding }} className="App">
        <div className='navBarContainer'>
          < MainAppBar />
        </div>
        {props.children}
      </div>
      <Footer/>
    </>
  );
};

export default Page;