import React from 'react';
import '../../App.css';
import Landing from './landing';
const HomePage = () => {
  return (
    <Landing/>
  );
}

export default HomePage;
