import React from "react";
import {
    FormGroup,
    FormLabel,
    TextField
} from '@material-ui/core';

const roundToTens = (value) => {
    return Math.round(value * 10) / 10;
}

const convertToPercent = (value) => {
    return `${Math.round(value * 100)}%`;
}

// const plusMin = (value, tableMeta, updateValue) => {
//     return tableMeta.rowData[5] - tableMeta.rowData[9];
// }

const colorize = (value, under, over) => {
    let color = 'black';
    if (value >= over) color = 'green';
    else if (value < under) color = 'red';

    return (
        <div style = {{ color }}>
            {value}
        </div>
    );
}

const colorAnim = (value, under, over) => {
    let color = 'black';
    if (parseFloat(value) >= over) color = 'green';
    else if (parseFloat(value) < under) color = 'red';

    return (
        <div className="animTable" style = {{ color }}>
            {value}
        </div>
    );
}

const renderOdds = (value) => {
    return value && (
        <div>
            {colorize(value.over, -110, 100)}
            {colorize(value.under, -110, 100)}
        </div>
    );
}

// const renderGameLine = (value) => {
//     return value && (
//         <div>
//             {value.totalPoints}
//         </div>
//     );
// }

// const sortGameLine = (order) => (a, b) => {
//     return (a.data.totalPoints < b.data.totalPoints ? -1: 1 ) * (order === 'desc' ? 1 : -1);
// }

const sortOdds = (order) => (a, b) => {
    return (a.data.over < b.data.over ? -1: 1 ) * (order === 'desc' ? 1 : -1);
}

const customFilter = (label) => {
    return {
        filterType: 'custom',        
        customFilterListOptions: {
            render: v => {
            if (v[0]) {
                return `Min ${label}: ${v[0]}`;
            } else if (v[1]) {
                return `Max ${label}: ${v[1]}`;
            }
            return [];
            },
            update: (filterList, filterPos, index) => {

            if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
                filterList[index] = [];
            }

            return filterList;
            },
        },
        filterOptions: {
            names: [],
            logic(age, filters) {
                if (filters[0] && filters[1]) {
                    return age < filters[0] || age > filters[1];
                } else if (filters[0]) {
                    return age < filters[0];
                } else if (filters[1]) {
                    return age > filters[1];
                }
                return false;
            },
            display: (filterList, onChange, index, column) => (
            <div>
                <FormLabel>{label}</FormLabel>
                <FormGroup row>
                <TextField
                    label='min'
                    value={filterList[index][0] || ''}
                    onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                    label='max'
                    value={filterList[index][1] || ''}
                    onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                />
                </FormGroup>
            </div>
            )
        }
    }
}

const commonColumnsPre = [
    // { name: "playerName", label: "Name" },
    // { name: "oppRank", label: "Opp Rank", options: { filter: false }},
    { name: "position", label: "Pos", options: { filterType: 'multiselect' }},
    { name: "opp", label: "Opp", options: { filterType: 'multiselect' }},
    { name: "matchup", label: "Matchup", options: { filterType: 'multiselect' }},
    { name: "gameTime", label: "Game Time", options: { filterType: 'multiselect' }},
    // { name: "+-", label: "+-", options: { customBodyRender: roundToTens }},
    { name: "O/U", label: "OU"},
    // { name: "fdOU", label: "FD OU" },
    { name: "percentDiff", label: "% Diff", options: { customBodyRender: (value) => colorAnim(convertToPercent(value), -5, 5), ...customFilter('% Diff') }},
    // { name: "proj+-", label: "Proj. +-", options: { customBodyRender: roundToTens }},
    // { name: "projPercentDiff", label: "Proj. % Diff", options: { customBodyRender: convertToPercent, ...customFilter('Proj % Diff') }},
    { name: "lasGPercentDiff", label: "Last Game %", options: { customBodyRender: convertToPercent }},
    { name: "lastGRes", label: "Last G", options: { customBodyRender: roundToTens }},
]

const commonColumnsPost = [
    { name: "dvpRank", label: "DVP Rank" },
    { name: "score", label: "Bet Score" },
    // { name: "gameLine", label: "Game Total", options: { customBodyRender: renderGameLine, sortCompare: sortGameLine }},
    { name: "gamesOver", label: "Games Over", options: { customBodyRender: convertToPercent }},
    { name: "lastFiveOver", label: "Last 5 Over"},
    { name: "dkOdds", label: "DK Odds", options: { customBodyRender: renderOdds, sortCompare: sortOdds }},
    { name: "fdOdds", label: "FD Odds", options: { customBodyRender: renderOdds, sortCompare: sortOdds }},
    // { name: "fdOverOdds", label: "FD Over Odds" },
    // { name: "fdUnderOdds", label: "FD Under Odds" },
    // { name:  "overOdds", label: "DK Over Odds", options: { customBodyRender: (value) => colorize(value, 1.9) }},
    // { name: "underOdds", label: "DK Under Odds", options: { customBodyRender: (value) => colorize(value, 1.9) }}
]

export const pts = [
    ...commonColumnsPre,
    { name: "pts", label: "Points", options: { customBodyRender: roundToTens }},
    { name: "oppDefVal", label: "Opp PPG", options: { customBodyRender: roundToTens }},
    { name: "oppDefRank", label: "Opp PPG Rank", options: { customBodyRender: roundToTens }},
    ...commonColumnsPost
]

export const reb = [
    ...commonColumnsPre,
    { name: "reb", label: "Rebounds", options: { customBodyRender: roundToTens }},
    { name: "oppDefVal", label: "Opp RPG", options: { customBodyRender: roundToTens }},
    { name: "oppDefRank", label: "Opp RPG Rank", options: { customBodyRender: roundToTens }},
    ...commonColumnsPost
]

export const ast = [
    ...commonColumnsPre,
    { name: "ast", label: "Assists", options: { customBodyRender: roundToTens }},
    { name: "oppDefVal", label: "Opp APG", options: { customBodyRender: roundToTens }},
    { name: "oppDefRank", label: "Opp APG Rank", options: { customBodyRender: roundToTens }},
    ...commonColumnsPost
]

export const pt3m = [
    ...commonColumnsPre,
    { name: "pt3m", label: "3PT Made", options: { customBodyRender: roundToTens }},
    { name: "oppDefVal", label: "Opp 3PMPG", options: { customBodyRender: roundToTens }},
    { name: "oppDefRank", label: "Opp 3PMPG Rank", options: { customBodyRender: roundToTens }},
    ...commonColumnsPost
]

export const pra = [
    ...commonColumnsPre,
    { name: "pra", label: "PRA", options: { customBodyRender: roundToTens }},
    { name: "oppDefVal", label: "Opp PPG", options: { customBodyRender: roundToTens }},
    { name: "oppDefRank", label: "Opp PPG Rank", options: { customBodyRender: roundToTens }},
    ...commonColumnsPost
]