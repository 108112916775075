import React from 'react';
import "./rankCircle.css"

const getNumberWithOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const roundToTens = (value) => {
    return Math.round(value * 10) / 10;
}
  
const RankCircle = (props) => {
    const { rank, teams, value, label, hideLabel } = props,
        percentage = rank/teams * 100;

    let color = 'gray';
    if(percentage > 66)  color = 'green';
    if(percentage < 33)  color = 'red';

    const svg = (
        <svg viewBox="0 0 36 36" className={`circular-chart ${color}`}>
            <path className="circle-bg"
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path className="circle"
                strokeDasharray={`${percentage}, 100`}
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            {/* <text x="18" y="20.35" class="percentage">{rank}</text> */}
            <text x="18" y="20.35" className="percentage">{getNumberWithOrdinal(rank)}</text>
        </svg>
    );

    return hideLabel ? (<div>{svg}</div>) :
        (<div className="flex-wrapper">
            { !hideLabel && <div className="label">
                <p>
                    <strong>{label}</strong>
                    <br/>
                    {roundToTens(value)}
                </p>
            </div>}
            <div className="single-chart">
                {svg}
            </div>
        </div>)
}

export default RankCircle;