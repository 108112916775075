// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/radar
import React from 'react';
import { ResponsiveRadar } from '@nivo/radar'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


const convertToPercent = (value) => {
    return `${Math.round(value * 100)}%`;
}
  
const roundToHundreds = (value) => {
    return Math.round(value * 100) / 100;
}

const Radar = ({ data, meta, mini }) => (
    <ResponsiveRadar
        data={data && Object.entries(data).filter((e) => e[0].includes('rank')).map((e) => ( { 'label': meta[e[0]].label, 'Rank': e[1] || []} ))}
        keys={[ 'Rank' ]}
        indexBy="label"
        maxValue={32}
        margin={ !mini && { top: 70, right: 80, bottom: 40, left: 80 }}
        curve="cardinalClosed"
        borderWidth={2}
        borderColor='#51ade5'
        gridLevels={3}
        gridShape="circular"
        gridLabelOffset={36}
        enableDots={ !mini && true}
        dotSize={10}
        dotColor='#51ade5'
        dotBorderWidth={2}
        dotBorderColor='#51ade5'
        enableDotLabel={false}
        dotLabel={(value, key) => {
            // console.log(value, key, data);
            return data.stats[value.index.split('_')[0]];
        }}
        gridLabel={value => {
            const { id, anchor } = value,
                key = Object.keys(meta).find(key => meta[key].label === id),
                rank = data.stats[key],
                percentage = rank/32 * 100;

            let stat = data.stats[meta[key].stat],
                color = 'gray';
            
            if(percentage > 66) color = 'green';
            if(percentage < 33) color = 'red';

            if (['BB%', 'K%'].includes(id)) stat = convertToPercent(stat);
            else if (['AB/HR', 'R/G', 'K/G'].includes(id)) stat = roundToHundreds(stat);
            
            return (
                <g transform={`translate(${anchor === 'end' ? -60 : anchor === 'middle' ? -30 : 0}, -20)`}>
                    <text>{id}</text>
                    <text
                        y={24}
                        style={{
                            fontSize: 24,
                            fontWeight: 'bold',
                            fill: color,
                        }}
                    >
                        {stat}
                    </text>
                </g>
            );
        }}
        dotLabelYOffset={-12}
        colors={['#51ade5']}
        fillOpacity={0.25}
        blendMode="multiply"
        animate={true}
        motionConfig="gentle"
        isInteractive={ !mini && true}
        tooltipFormat={(value, key, index) => {
            // console.log('TT format',value, key, index, data);
            // return `${(value - 33) * -1}/32`;
            return `${value}/32`;
        }}
        // legends={[
        //     {
        //         anchor: 'top-left',
        //         direction: 'column',
        //         translateX: -50,
        //         translateY: -40,
        //         itemWidth: 80,
        //         itemHeight: 20,
        //         itemTextColor: '#999',
        //         symbolSize: 12,
        //         symbolShape: 'circle',
        //         effects: [
        //             {
        //                 on: 'hover',
        //                 style: {
        //                     itemTextColor: '#000'
        //                 }
        //             }
        //         ]
        //     }
        // ]}
    />
)

export default Radar;