import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { useFetchBlogPost } from "../../store/props";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Link } from "react-router-dom";
import '../../App.css';

const useStyles = makeStyles((theme) => ({
    header: {
        color: '#264d66',
        fontFamily: "Audiowide",
    },
    root: {
        maxWidth: 1000,
        margin: '50px auto',
        minWidth: '25%',
        height: 'fit-content'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: '#264d66',
    },
    sub: {
        color: 'black',
        fontFamily: 'OpenSans',
    },
    blogDate: { marginLeft: 'auto' },
    // h5: {},
    // table: {},
    // th: {},
    // td: {},
    // figcaption: {},
    content: {
        width: '70%',
        margin: 'auto',
        textAlign: 'left',
        "& $h4": {
            color: '#51ade5',
            fontFamily: "Audiowide",
            fontSize: 'large', 
            fontWeight: 'bold',
            marginBottom: 0,
        },
        "& $h6": {
            color: '#264d66',
            fontFamily: "Audiowide",
            marginTop: 0,
        },
        "& $table, $th, $td": {
            margin: "auto",
            color: "#264d66",
            border: '1px solid #264d66',
            borderCollapse: 'collapse',
            padding: '2px',
        },
        "& $table": {
            width: '100%',
        },
        "& $figcaption": {
            color: '#51ade5',
            textAlign: 'center',
            fontSize:'smaller',
        }
    },
    '@media screen and (max-width: 700px)': {
        // figure: {},
        content: {
            width: '100%',
            "& $figure": {
                overflow: 'scroll',
                margin: '2px',
            },
        },
    },
}));

const BlogPost = (props) => {
    const classes = useStyles();
    const blogId = props.match.params.id;
    const { blogPost, loading } = useFetchBlogPost(blogId),
        { title, content, featured_image, date } = blogPost,
        parsedTitle = title ? parse(title) : '',
        localDate = date ? new Date(date).toLocaleDateString() : '';

    return (
        <div>
            {!loading && blogPost && content && (
                <Card className={classes.root}>
                    <CardHeader
                        disableTypography
                        className={classes.header}
                        // action={
                        //     <IconButton aria-label="settings">
                        //         <MoreVertIcon />
                        //     </IconButton>
                        // }
                        title={parsedTitle}
                    />
                    { featured_image && 
                        <CardMedia
                            className={classes.media}
                            image={featured_image}
                            title={parsedTitle}
                        />
                    }
                    <CardActions disableSpacing>
                        {/* <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                        </IconButton>
                        <IconButton aria-label="share">
                            <ShareIcon />
                        </IconButton> */}
                        <div className={classes.blogDate}>{localDate}</div>
                    </CardActions>
                    <CardContent className={classes.sub}>
                        <div className={classes.content} dangerouslySetInnerHTML = {{ __html: content }} />
                    </CardContent>
                </Card>
            )}
            <Link className='alink' to="/">
                {'View All Posts >>'}
            </Link>
        </div>
    );
}

export default BlogPost;