import  React, { useState } from 'react';
import '../../App.css';
import PropTable from '../table/index';
import { useFetchProps } from "../../store/props";
import InfoIcon from '@material-ui/icons/Info';
import CustomizedDialogs from "../dialog/index";
import { IconButton } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = () => ({
  form: {
    zIndex: 11,
    margin: '40px',
  },
  formControl: {
    minWidth: '200px',
    padding: '10px',
  },
});

const teamRanks = [
  { label: 'Pass Att', rank: 'oppPassAttRank', value: 'oppPassAtt/G' },
  { label: 'Pass Cmp', rank: 'oppPassCmpRank', value: 'oppPassCmp/G' },
  { label: 'Pass Yds', rank: 'oppPassYdsRank', value: 'oppPassYds/G' },
  { label: 'Rush Att', rank: 'oppRushAttRank', value: 'oppRushAtt/G' },
  { label: 'Rush Yds', rank: 'oppRushYdsRank', value: 'oppRushYds/G' },
  { label: 'Rush Avg', rank: 'oppRushAvgRank', value: 'rushYdsPA' },
];

const rankOptions = {
  passComp: [
    { label: 'Pass Cmp', rank: 'oppPassCmpRank', value: 'oppPassCmp/G' },
    { label: 'Pass Att', rank: 'oppPassAttRank', value: 'oppPassAtt/G' },
    { label: 'Pass Yds', rank: 'oppPassYdsRank', value: 'oppPassYds/G' },
  ],
  passYds: [
    { label: 'Pass Cmp', rank: 'oppPassCmpRank', value: 'oppPassCmp/G' },
    { label: 'Pass Att', rank: 'oppPassAttRank', value: 'oppPassAtt/G' },
    { label: 'Pass Yds', rank: 'oppPassYdsRank', value: 'oppPassYds/G' },
  ],
  passAtt: [
    { label: 'Pass Cmp', rank: 'oppPassCmpRank', value: 'oppPassCmp/G' },
    { label: 'Pass Att', rank: 'oppPassAttRank', value: 'oppPassAtt/G' },
    { label: 'Pass Yds', rank: 'oppPassYdsRank', value: 'oppPassYds/G' },
  ],
  passInt: [
    { label: 'Int', rank: 'oppPassIntRank', value: 'oppPassInt/G' },
    { label: 'Pass Cmp', rank: 'oppPassCmpRank', value: 'oppPassCmp/G' },
    { label: 'Pass Att', rank: 'oppPassAttRank', value: 'oppPassAtt/G' },
    { label: 'Pass Yds', rank: 'oppPassYdsRank', value: 'oppPassYds/G' },
  ],
  rushAtt: [
    { label: 'Rush Att', rank: 'oppRushAttRank', value: 'oppRushAtt/G' },
    { label: 'Rush Yds', rank: 'oppRushYdsRank', value: 'oppRushYds/G' },
    { label: 'Rush Avg', rank: 'oppRushAVGRank', value: 'oppRushAVG' },
  ],
  rushYds: [
    { label: 'Rush Att', rank: 'oppRushAttRank', value: 'oppRushAtt/G' },
    { label: 'Rush Yds', rank: 'oppRushYdsRank', value: 'oppRushYds/G' },
    { label: 'Rush Avg', rank: 'oppRushAVGRank', value: 'oppRushAVG' },
  ],
  rec: [
    { label: 'Rec', rank: 'oppRecRank', value: 'oppRec/G' },
    { label: 'Rec Yds', rank: 'oppRecYdsRank', value: 'oppRecYds/G' },
    { label: 'Targets', rank: 'oppTgtRank', value: 'oppTgt/G' },
  ],
  recYds: [
    { label: 'Rec', rank: 'oppRecRank', value: 'oppRec/G' },
    { label: 'Rec Yds', rank: 'oppRecYdsRank', value: 'oppRecYds/G' },
    { label: 'Targets', rank: 'oppTgtRank', value: 'oppTgt/G' },
  ],
};

const options = [
  { value: 'passComp', label: 'Completed Passes',
    headers: [ 'Week', 'Opp', 'Opp Rank',  'Pass Att', 'Pass Cmp', 'Pass Yds', 'Pass Avg', 'Cmp %', 'Snaps' ],
    columns: [ 'week', 'opp', 'standardDef', 'passAtt', 'passComp', 'passYds', 'passAvg', 'passPct','snapsOff' ],
    maxSliderValue: 40,
  },
  { value: 'passYds', label: 'Passing Yards',
    headers: [ 'Week', 'Opp', 'Opp Rank',  'Pass Att', 'Pass Cmp', 'Pass Yds', 'Pass Avg', 'Cmp %', 'Snaps' ],
    columns: [ 'week', 'opp', 'standardDef', 'passAtt', 'passComp', 'passYds', 'passAvg', 'passPct','snapsOff' ],
    maxSliderValue: 400,
  },
  { value: 'passAtt', label: 'Attempted Passes',
    headers: [ 'Week', 'Opp', 'Opp Rank',  'Pass Att', 'Pass Cmp', 'Pass Yds', 'Pass Avg', 'Cmp %', 'Snaps' ],
    columns: [ 'week', 'opp', 'standardDef', 'passAtt', 'passComp', 'passYds', 'passAvg', 'passPct','snapsOff' ],
    maxSliderValue: 60,
  },
  { value: 'passInt', label: 'Interceptions',
    headers: [ 'Week', 'Opp', 'Opp Rank',  'Int', 'Pass Att', 'Pass Cmp', 'Pass Yds', 'Pass Avg', 'Cmp %', 'Snaps' ],
    columns: [ 'week', 'opp', 'standardDef', 'passInt', 'passAtt', 'passComp', 'passYds', 'passAvg', 'passPct','snapsOff' ],
    maxSliderValue: 5,
  },
  { value: 'rushAtt', label: 'Rushing Attempts',
    headers: [ 'Week', 'Opp', 'Opp Rank',  'Rush Att', 'Rush Yds', 'Rush Avg', 'Snaps' ],
    columns: [ 'week', 'opp', 'standardDef', 'rushAtt', 'rushYds', 'rushAvg', 'snapsOff' ],
    maxSliderValue: 30,
  },
  { value: 'rushYds', label: 'Rushing Yards',
    headers: [ 'Week', 'Opp', 'Opp Rank',  'Rush Att', 'Rush Yds', 'Rush Avg', 'Snaps' ],
    columns: [ 'week', 'opp', 'standardDef', 'rushAtt', 'rushYds', 'rushAvg', 'snapsOff' ],
    maxSliderValue: 125,
  },
  { value: 'rec', label: 'Receptions',
    headers: [ 'Week', 'Opp', 'Opp Rank',  'Rec', 'Rec Yds', 'Rec Avg', 'Targets', 'Yds/Tgt', 'Snaps' ],
    columns: [ 'week', 'opp', 'standardDef', 'rec', 'recYds', 'recAvg', 'targets', 'ydsTarget', 'snapsOff' ],
    maxSliderValue: 20,
  },
  { value: 'recYds', label: 'Receiving Yards',
    headers: [ 'Week', 'Opp', 'Opp Rank',  'Rec', 'Rec Yds', 'Rec Avg', 'Targets', 'Yds/Tgt', 'Snaps' ],
    columns: [ 'week', 'opp', 'standardDef', 'rec', 'recYds', 'recAvg', 'targets', 'ydsTarget', 'snapsOff' ],
    maxSliderValue: 125,
  },
  { label: 'Futures' },
  { value: 'passYds_futures', label: 'Passing Yard Futures', type: 'passYds',
    headers: [ 'Year', 'Team', 'G', 'Yards', 'Yds/G', 'Attempts' ],
    columns: [ 'season', 'team', 'games', 'passYds', [ 'passYds', 'games' ], 'passAtt'],
    maxSliderValue: 4800, useSeasonStats: true,
    focusedStats: [
      { label: 'Pass Yds', value: 'passYds' },
      { label: 'OU', isOU: true, value: 'O/U' },
      { label: 'Pass Yds / GP', formula: [ 'passYds', 'games' ] },
      { label: 'OU / GP', isOU: true, formula: [ 'O/U', 17 ] },
    ]
  },
  { value: 'passTD_futures', label: 'Passing TD Futures', type: 'passTD',
    headers: [ 'Year', 'Team', 'G', 'TD', 'TD/G', 'Attempts' ],
    columns: [ 'season', 'team', 'games', 'passTD', [ 'passTD', 'games' ], 'passAtt'],
    maxSliderValue: 40, useSeasonStats: true,
    focusedStats: [
      { label: 'Pass TD', value: 'passTD' },
      { label: 'OU', isOU: true, value: 'O/U' },
      { label: 'Pass TD / GP', formula: [ 'passTD', 'games' ] },
      { label: 'OU / GP', isOU: true, formula: [ 'O/U', 17 ] },
    ]
  },
  { value: 'passInt_futures', label: 'Passing INT Futures', type: 'passInt',
    headers: [ 'Year', 'Team', 'G', 'INT', 'INT/G', 'Attempts' ],
    columns: [ 'season', 'team', 'games', 'passInt', [ 'passInt', 'games' ],'passAtt'],
    maxSliderValue: 40, useSeasonStats: true,
    focusedStats: [
      { label: 'Pass INT', value: 'passInt' },
      { label: 'OU', isOU: true, value: 'O/U' },
      { label: 'Pass INT / GP', formula: [ 'passInt', 'games' ] },
      { label: 'OU / GP', isOU: true, formula: [ 'O/U', 17 ] },
    ]
  },
  { value: 'rushYds_futures', label: 'Rushing Yard Futures', type: 'rushYds',
    headers: [ 'Year', 'Team', 'G', 'Yards', 'Yds/G', 'Attempts' ],
    columns: [ 'season', 'team', 'games', 'rushYds', [ 'rushYds', 'games' ], 'rushAtt'],
    maxSliderValue: 4800, useSeasonStats: true,
    focusedStats: [
      { label: 'Rush Yds', value: 'rushYds' },
      { label: 'OU', isOU: true, value: 'O/U' },
      { label: 'Rush Yds / GP', formula: [ 'rushYds', 'games' ] },
      { label: 'OU / GP', isOU: true, formula: [ 'O/U', 17 ] },
    ]
  },
  { value: 'rushTD_futures', label: 'Rushing TD Futures', type: 'rushTD',
    headers: [ 'Year', 'Team', 'G', 'TD', 'TD/G', 'Attempts' ],
    columns: [ 'season', 'team', 'games', 'rushTD', [ 'rushTD', 'games' ], 'rushAtt'],
    maxSliderValue: 40, useSeasonStats: true,
    focusedStats: [
      { label: 'Rush TD', value: 'rushTD' },
      { label: 'OU', isOU: true, value: 'O/U' },
      { label: 'Rush TD / GP', formula: [ 'rushTD', 'games' ] },
      { label: 'OU / GP', isOU: true, formula: [ 'O/U', 17 ] },
    ]
  },
  { value: 'recYds_futures', label: 'Rec Yard Futures', type: 'recYds',
    headers: [ 'Year', 'Team', 'G', 'Yards', 'Yds/G', 'Rec' ],
    columns: [ 'season', 'team', 'games', 'recYds', [ 'recYds', 'games' ], 'rec'],
    maxSliderValue: 4800, useSeasonStats: true,
    focusedStats: [
      { label: 'Rec Yds', value: 'recYds' },
      { label: 'OU', isOU: true, value: 'O/U' },
      { label: 'Rec Yds / GP', formula: [ 'recYds', 'games' ] },
      { label: 'OU / GP', isOU: true, formula: [ 'O/U', 17 ] },
    ]
  },
  { value: 'rec_futures', label: 'Rec Futures', type: 'rec',
    headers: [ 'Year', 'Team', 'G', 'Rec', 'Rec/G', 'Yards' ],
    columns: [ 'season', 'team', 'games', 'rec', [ 'rec', 'games' ], 'recYds'],
    maxSliderValue: 200, useSeasonStats: true,
    focusedStats: [
      { label: 'Rec', value: 'rec' },
      { label: 'OU', isOU: true, value: 'O/U' },
      { label: 'Rec / GP', formula: [ 'rec', 'games' ] },
      { label: 'OU / GP', isOU: true, formula: [ 'O/U', 17 ] },
    ]
  },
  { value: 'recTD_futures', label: 'Rec TD Futures', type: 'recTD',
    headers: [ 'Year', 'Team', 'G', 'TD', 'TD/G', 'Rec' ],
    columns: [ 'season', 'team', 'games', 'recTD', [ 'recTD', 'games' ], 'rec'],
    maxSliderValue: 40, useSeasonStats: true,
    focusedStats: [
      { label: 'Rec TD', value: 'recTD' },
      { label: 'OU', isOU: true, value: 'O/U' },
      { label: 'Rec TD / GP', formula: [ 'recTD', 'games' ] },
      { label: 'OU / GP', isOU: true, formula: [ 'O/U', 17 ] },
    ]
  },
];

let activeFilters = [];
const NFLData = withStyles(styles)((props) => {
  const { classes } = props;
  const sport = 'nfl';
  const [type, setType] = useState(options[0].value);
  const [selectedOption, setSelectedOption] = useState('passComp');
  const [selectedLabel, setSelectedLabel] = useState('Completed Passes');
  const previousSelected = false;//[previousSelected, setPreviousSelected] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [value, setValue] = React.useState('all');
  const [oppStatMeta, setOppStatMeta] = useState(rankOptions['passComp']);
  const [ selectedObject, setSelectedObject ] = useState(options[0]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const propTable = React.createRef();
  const changeBetType = (event) => {
    const opt = options.find(x => x.value === event.target.value);
    const activeColumns = propTable.current.state.columns;
    activeFilters = propTable.current.tableRef.current.state.filterList.map((filter, i) => {
      if (filter.length) {
        return {
          filter,
          column: activeColumns[i],
        }
      } else {
        return false;
      }
    }).filter(filter => filter);

    setSelectedOption(event.target.value);
    setSelectedLabel(opt.label);
    setOppStatMeta(rankOptions[event.target.value]);
    setSelectedObject(opt);
    setType(opt.type || opt.value);
  };

  const { propData, columns, loading } = useFetchProps(sport, selectedOption);

  return (
    <>
    <CustomizedDialogs
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      data={dialogData}
      propType={selectedLabel}
      type={type}
      sport={sport}
      meta={oppStatMeta}
      teamRanks={teamRanks}
      headers={selectedObject.headers}
      columns={selectedObject.columns}
      maxSliderValue={selectedObject.maxSliderValue}
      useSeasonStats={selectedObject.useSeasonStats}
      focusedStats={selectedObject.focusedStats}
      xAxis="week"
    />
    {/* <h1 className='mainHeader'>NFL Betting Odds</h1> */}
    <div style={{ textAlign: 'left' }}>
      <div className={classes.form}>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend">Bet Type</FormLabel>
          <Select
            labelId="bet-type-filter-label"
            id="bet-type-filter"
            value={selectedOption}
            onChange={changeBetType}
            displayEmpty
          >
            {options.map(option => {
              if (option.value) return <MenuItem key={option.value} value={option.value} name={option.label}>{option.label}</MenuItem>
              else return <ListSubheader key={option.label}>{option.label}</ListSubheader>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend">Sports Book</FormLabel>
            <Select
              labelId="sports-book-label"
              id="sports-book-filter"
              value={value}
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem value="all">Any</MenuItem>
              <MenuItem value="draftKings">DraftKings</MenuItem>
              <MenuItem value="fanDuel">FanDuel</MenuItem>
            </Select>
        </FormControl>
      </div>
    </div>
    <div style={{
      margin: 10
    }}>
      { loading ? <LinearProgress /> : (
        <PropTable
          ref={propTable}
          key="propTable"
          data={propData.filter(data => {
            if(value === 'fanDuel') {
              return !!data.fdOdds;
            } else if(value === 'draftKings') {
              return !!data.dkOdds;
            }
            return true;
          })}
          type={selectedOption}
          sport={sport}
          label={selectedLabel}
          loading={loading}
          columns={
            [
              {
                name: "playerName", label: "Name", options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                      <div style={{display:"flex", alignItems:"center"}}>
                        <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => {
                          let playerData = propData.find(player => player.playerName === tableMeta.rowData[0])
                          setModalVisible(true);
                          setDialogData(playerData);
                        }}>
                          <InfoIcon style={{color:"#51ade5"}}/>
                        </IconButton>
                        {value}
                      </div>
                    )
                },
                setCellProps: () => ({ style: {
                    position: "sticky",
                    left: 0,
                    background: "#ffffffdd",
                    backdropFilter: "blur(2px)",
                    zIndex: 101
                }}),
                setCellHeaderProps: () => ({ style: {
                    position: "sticky",
                    left: 0,
                    top: 0,
                    zIndex: 102
                }}),
            }},
              ...(previousSelected ? columns : columns.filter(column => {
                let sportsBook = [];
                if(value === 'fanDuel') sportsBook = ['O/U', 'dkOdds'];
                if(value === 'draftKings') sportsBook = ['fdOU', 'fdOdds'];
                return !['lastGRes', 'lasGPercentDiff', 'lasGDiff', ...sportsBook].includes(column.name);
              }).map(column => {
                const activeFilter = activeFilters.find(x => x.column.name === column.name);
                if (activeFilter) {
                  column.options.filter = true;
                  column.options.filterList = activeFilter.filter;
                }

                return column;
              })),
          ]}
        />
      )}
    </div>
    </>
  );
});

export default NFLData;