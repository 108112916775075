import { useState, useEffect } from "react";
import fire from "../fire";

export const useFetchProps = (sport, type, previous) => {
  const [propData, setPropData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (sport && type) {
      const jsObj = require(`../config/columns/${sport}.js`);
      setColumns( jsObj[type])

      if(!previous) {
        const storage = fire.storage();

        // Create a reference to the file we want to download
        const dataRef = storage.ref(`data/${sport}/${sport}_${type}.json`);

        // Get the download URL
        dataRef.getDownloadURL()
          .then((url) => {
            fetch(url)
              .then(response => response.json())
              .then((jsonData) => {
                setPropData(jsonData);
                setLoading(false);
              })
              .catch((error) => {
                console.error(error)
              })
          })
          .catch((error) => {
            console.error(error)
          })

      } else {
        fetch(`${process.env.PUBLIC_URL}/data/${sport}_${type}${previous ? '_prev' : ''}.json`)
          .then(response => response.json())
          .then((jsonData) => {
            console.log(jsonData)
            setPropData(jsonData);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error)
          })
      }

    }
  }, [sport, type, previous]);

  return { propData, columns, loading };
};

export const useFetchBlog = (sport) => {
  const [ blogPosts, setBlogPosts ] = useState([]);
  const [loading, setLoading] = useState(true);
  const tag = sport ? `?tag=${sport}` : '';

  useEffect(() => {
    setLoading(true);

    fetch(`https://public-api.wordpress.com/rest/v1.1/sites/btodds.wordpress.com/posts${tag}`)
      .then(response => response.json())
      .then((jsonData) => {
        setBlogPosts(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error)
        const storage = fire.storage();

        // Create a reference to the file we want to download
        const dataRef = storage.ref(`blog/posts.json`);

        // Get the download URL
        dataRef.getDownloadURL()
          .then((url) => {
            fetch(url)
              .then(response => response.json())
              .then((jsonData) => {
                setBlogPosts(jsonData);
                setLoading(false);
              })
              .catch((error) => {
                console.error(error)
              })
          })
          .catch((error) => {
            console.error(error)
          })
      })
  }, [sport, tag]);

  return { blogPosts, loading };

};

export const useFetchBlogPost = (postSlug) => {
  const [ blogPost, setBlogPost ] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    fetch(`https://public-api.wordpress.com/rest/v1.1/sites/btodds.wordpress.com/posts/slug:${postSlug}`)
      .then(response => response.json())
      .then((jsonData) => {
        setBlogPost(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        const storage = fire.storage();

        // Create a reference to the file we want to download
        const dataRef = storage.ref(`blog/${postSlug}.json`);

        // Get the download URL
        dataRef.getDownloadURL()
          .then((url) => {
            fetch(url)
              .then(response => response.json())
              .then((jsonData) => {
                setBlogPost(jsonData);
                setLoading(false);
              })
              .catch((error) => {
                console.error(error)
              })
          })
          .catch((error) => {
            console.error(error)
          })
      })
  }, [postSlug]);

  return { blogPost, loading };

};