import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import parse from 'html-react-parser';
import '../../App.css';

const useStyles = makeStyles((theme) => ({
    header: {
        color: '#264d66',
        fontFamily: "Audiowide",
    },
    root: {
        maxWidth: 1000,
        margin: '50px auto',
        minWidth: '25%',
        height: 'fit-content',
        fontFamily: 'OpenSans',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: '#264d66',
    },
    sub: {
        color: 'black',
    },
    blogDate: { marginLeft: 'auto' },
    h5: {},
    table: {},
    th: {},
    td: {},
    figcaption: {},
    content: {
        textAlign: 'left',
        "& $h5": {
            color: '#51ade5',
            // fontFamily: "Audiowide",
            fontSize: 'large', 
            fontWeight: 'bold',
        },
        "& $table, $th, $td": {
            margin: "auto",
            color: "#264d66",
            border: '1px solid #264d66',
            borderCollapse: 'collapse',
            padding: '2px',
        },
        "& $figcaption": {
            color: '#51ade5',
            textAlign: 'center',
            fontSize:'smaller',
        }
      }
}));

const BlogCard = (props) => {
  const classes = useStyles();
  // const [expanded, setExpanded] = React.useState(false);
  const { post } = props,
    { title, excerpt, featured_image, date, slug } = post,
    parsedTitle = parse(title),
    localDate = date ? new Date(date).toLocaleDateString() : '';

  return (
    <Card className={classes.root}>
      <CardHeader
        disableTypography
        className={classes.header}
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={parsedTitle}
        // subheader={excerpt}
      />
      {featured_image && <CardMedia
        className={classes.media}
        image={featured_image}
        title={parsedTitle}
      />}
      <CardActions disableSpacing>
          <div className={classes.blogDate}>{localDate}</div>
      </CardActions>
      <CardContent>
        <div dangerouslySetInnerHTML = {{ __html: excerpt }} />
        <Link className='alink' to={`/blog/${slug}`}>
          {'Read More >>'}
        </Link>
      </CardContent>
    </Card>
  );
}

export default BlogCard;