import React from 'react';
import '../../App.css';

const AboutPage = () => {

  return (
    <div>
        <h1 className='mainHeader'>Welcome to Beat The Odds</h1>
        <div style={{textAlign:'left', maxWidth:'800px', margin:'auto'}}>
            <h2 style={{fontFamily:'Audiowide', color: '#264d66' }}>About</h2>
            <p>
                Our free tools will help you discover and analyze a variety of bettings odds all in one place! <br/><br/>
                These are the sports and bet types that we currently provide analytics for: <br/>
                NFL (Player Props: Passing Completions & Yards, Receptions & Receiving Yards, Rushing Attempts & Yards)<br/>
                NBA (Player Props: Points, Rebounds, Assists, 3PTM, PRA)<br/>
                MLB (Player Props: StrikeOuts &middot; Futures: HomeRuns, Hits, RBIs)<br/>
                <br/>
                Get started by selecting a sport & then find your best bet by comparing different sportsbooks and lines.
            </p>
            <h2 style={{fontFamily:'Audiowide', color: '#264d66' }}>Feedback</h2>
            <p>
                We're just getting started and greatly value any feedback you might have. Leave us a comment by clicking on the feedback icon in the nav bar.
            </p>
            <h2 style={{fontFamily:'Audiowide', color: '#264d66' }}>Social Media</h2>
            <p>
                Follow us! <br/><br/>
                Twitter: <a className='alink' target='blank' href='https://twitter.com/1beattheodds/'>@1BeatTheOdds</a><br/>
                Instagram: <a className='alink' target='blank' href='https://www.instagram.com/1beattheodds/'>@1BeatTheOdds</a><br/>
                Reddit: <a className='alink' target='blank' href='https://www.reddit.com/user/beattheodds_'>u/BeatTheOdds_</a><br/>
            </p>
        </div>
        <img alt="Beat The Odds: sports betting backed by stats and analytics"
            style={{ margin:'20px', maxWidth: '85vw' }}
            src={`${process.env.PUBLIC_URL}/logos/main-logo.png`}
        />
    </div>
  );
}

export default AboutPage;
