import React from 'react';
import '../../App.css';
import { useFetchBlog } from "../../store/props";
import BlogCard from '../blog/card';

const HomePage = () => {
  const { blogPosts, loading } = useFetchBlog();

  return (
    <>
      <h1 className='mainHeader'>Beat The Odds - Recent Blog Posts</h1>
      {!loading && blogPosts && blogPosts.posts.map(post => (
        < BlogCard key={post.ID} post={post} />
      ))}

    </>
  );
}

export default HomePage;
