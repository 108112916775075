import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radar from '../charts/radar';
import RankCircle from '../charts/rankCircle';
import SwipeableTabs from './tabs';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

const useStyles = makeStyles((theme) => ({
  rankGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
      // gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
  },
  '@media screen and (max-width: 960px)': {
      rankGrid: {
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      },
  },
  formControl: {
    minWidth: '100px',
    padding: '10px',
  },
  lineChart: {
    color: 'black',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
      backgroundColor: "white",
  },
  detail: {
      color: "gray",
  },
  header: {
    textShadow: '1px 1px 2px #264d66',
    color: 'white',
    backgroundColor: "#51ade5",
  },
  odds: {
    background: '#ffffff55',
    textAlign: 'center',
    alignItems: 'center',
    display: 'grid',
    padding: '4px 10px',
    borderRadius: '4px',
    marginRight: '10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: 'white',
    fontFamily: 'Audiowide',
  },
  subHeader: {
    color: '#51ade5',
    fontFamily: 'Audiowide',
  },
  divLink: {
    textDecoration: 'none',
    display: 'contents',
  },
  dk: {
    backgroundImage: 'url(logos/dk_25.svg)',
  },
  fd: {
    backgroundImage: 'url(logos/fd_25.svg)',
  },
  content: { background: '#eeeeee' },
  titleWrapper: { display: 'flex', marginRight: '30px' },
  red: { background: '#FF000030' },
  green: { background: '#00800030' },
}));

const convertToPercent = (value) => {
  return `${Math.round(value * 100)}%`;
}

const roundToHundreds = (value) => {
    return Math.round(value * 100) / 100;
}

const colorize = (value, under, over) => { 
  let color = 'black';
  if (parseFloat(value) > over) color = 'green';
  else if (parseFloat(value) < under) color = 'red';

  return (
      <div style = {{ color }}>
          {value}
      </div>
  );
}

const meta = {
    kRate_rank: { label: 'K%', stat: 'kRate' },
    kPerGame_rank: { label: 'K/G', stat: 'kPerGame' },
    bbRate_rank: { label: 'BB%', stat: 'bbRate' },
    runsPerGame_rank: { label: 'R/G', stat: 'runsPerGame' },
    avg_rank: { label: 'AVG', stat: 'avg' },
    ops_rank: { label: 'OPS', stat: 'ops' },
    abPerHR_rank: { label: 'AB/HR', stat: 'atBatsPerHomeRun' },
}

const MLBOpponent = (props) => {
  const { lineup, oppStats, PH } = props;
  const [ year, setYear ] = useState('currentYear');
  const [ split, setSplit ] = useState('stats');
  const classes = useStyles();

  return (
    <div>
        { oppStats && 
            <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.subHeader}>{oppStats.teamName}: Stats</Typography>
                </AccordionSummary>
                <AccordionDetails  style={{ padding: "0px" }}>
                    {/* <h4 className={classes.subHeader}>{oppStats.teamName}</h4> */}
                    <SwipeableTabs tabs={['Expanded','Condensed']} icons={[<DataUsageIcon/>, <TrackChangesIcon/>]}>
                        <div className={classes.rankGrid}>
                            <RankCircle rank={oppStats.stats.kRate_rank} teams={32} label='K%' value={convertToPercent(oppStats.stats.kRate)} />
                            <RankCircle rank={oppStats.stats.kPerGame_rank} teams={32} label='K/G' value={roundToHundreds(oppStats.stats.kPerGame)} />
                            <RankCircle rank={oppStats.stats.bbRate_rank} teams={32} label='BB%' value={convertToPercent(oppStats.stats.bbRate)} />
                            <RankCircle rank={oppStats.stats.runsPerGame_rank} teams={32} label='R/G' value={roundToHundreds(oppStats.stats.runsPerGame)} />
                            <RankCircle rank={oppStats.stats.avg_rank} teams={32} label='AVG' value={oppStats.stats.avg} />
                            <RankCircle rank={oppStats.stats.ops_rank} teams={32} label='OPS' value={oppStats.stats.ops} />
                            <RankCircle rank={oppStats.stats.abPerHR_rank} teams={32} label='AB/HR' value={oppStats.stats.atBatsPerHomeRun} />
                        </div>
                        <div style={{height: '400px'}}>
                            <Radar data={oppStats} meta={meta}/>
                        </div>
                    </SwipeableTabs>
                </AccordionDetails>
            </Accordion>
        }
        
        { lineup && 
            <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
            <Typography className={classes.subHeader}>Opposing Lineup</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <div>
                <FormControl className={classes.formControl}>
                <FormLabel component="legend">Season</FormLabel>
                <Select
                    labelId="year-select-placeholder-label-label"
                    id="year-select-placeholder-label"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="currentYear">2022</MenuItem>
                    <MenuItem value="lastYear">2021</MenuItem>
                </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                <FormLabel component="legend">Split</FormLabel>
                <Select
                    labelId="stats-select-placeholder-label-label"
                    id="stats-select-placeholder-label"
                    value={split}
                    onChange={(e) => setSplit(e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="stats">Overall</MenuItem>
                    <MenuItem value="splits">{`VS ${PH}`}</MenuItem>
                </Select>
                </FormControl>
            </div>
            <table className='gamelog'>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Bats</th>
                    <th>AB</th>
                    <th>AVG</th>
                    <th>K/AB</th>
                </tr>                           
                </thead>
                <tbody>
                    { lineup.map(player => 
                    <tr key={player.name}>
                        <td>{player.name}</td>
                        <td>{player.bats}</td>
                        <td>{player[year] ? player[year][split].atBats : ''}</td>
                        <td>{player[year] ? player[year][split].avg : ''}</td>
                        <td>{player[year] && player[year][split] && player[year][split].atBats > 0 
                          ? colorize(convertToPercent(player[year][split].strikeOuts / player[year][split].atBats), 18, 22)
                          : ''}</td>
                    </tr>
                    )}
                </tbody>
            </table>
            </AccordionDetails>
        </Accordion>
        }
    </div>
  );
}

export default MLBOpponent;