import  React from 'react';
import '../../App.css';
import PickCard from '../card/index';

const picks = [
  {
    title: 'Ingram U 11.5 Rush Attempts',
    subheader: '-135',
    analysis: 'Ingram is averaging 9 RA/G and has yet to surpass 11 rushing attempts this year despite 5/6 games having a very favorable game script (blowout wins).'
  },
  {
    title: 'Ingram U 55.5 Rush Attempts',
    subheader: '-115',
    analysis: 'Ingram is averaging 39 RY/G and has yet to surpass 11 rushing attempts this year despite 5/6 games having a very favorable game script (blowout wins).'
  }
]
const NFLPicks = () => {
  return (
    <>
      <div style={{
        display:'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        {picks.map(pick => {
          return (
            < PickCard
              key={pick.title}
              title={pick.title}
              subheader={pick.subheader}
              analysis={pick.analysis}
            />
          )
        })}
      </div>
    </>
  );
}

export default NFLPicks;
