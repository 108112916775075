import  React, { useState } from 'react';
import '../../App.css';
import PropTable from '../table/index';
import { useFetchProps } from "../../store/props";
import { IconButton } from '@material-ui/core';
import CustomizedDialogs from "../dialog/index";
import InfoIcon from '@material-ui/icons/Info';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';

const styles = () => ({
  form: {
    zIndex: 11,
    margin: '40px',
  },
  formControl: {
    minWidth: '200px',
    padding: '10px',
  },
});

const options = [
  { value: 'strikeOuts', label: 'StrikeOuts', type: 'strikeOuts',
    headers: [ 'Date', 'Opp', 'Started',  'K', 'IP', 'P', 'BF', 'K%' ],
    columns: [ 'date', 'opp', 'started', 'strikeOuts', 'inningsPitched', 'numberOfPitches', 'atBats', ['strikeOuts','atBats'] ],
  },
  { label: 'Futures' },
  { value: 'hr_futures', label: 'HR Futures', type: 'hr', customColumns:
    (player) => {
      if(player) {
        return {
          hrAB: player.hr / player.ab
        }
      }
    },
    headers: [ 'Year', 'Team', 'G', 'AB', 'HR', 'HR/AB' ],
    columns: [ 'season', 'team', 'games', 'ab', 'hr', ['hr','ab']],
    useSeasonStats: true,
  },
  { value: 'hits_futures', label: 'Hits Futures', type: 'h',
    headers: [ 'Year', 'Team', 'G', 'AB', 'H', 'AVG' ],
    columns: [ 'season', 'team', 'games', 'ab', 'h', 'avg'],
    useSeasonStats: true,
  },
  { value: 'rbis_futures', label: 'RBIs Futures', type: 'rbi',
    headers: [ 'Year', 'Team', 'G', 'AB', 'RBI', 'RBI/AB' ],
    columns: [ 'season', 'team', 'games', 'ab', 'rbi', ['rbi','ab']],
    useSeasonStats: true,
  },
];

const MLBData = withStyles(styles)((props) => {
  const { classes } = props;
  const sport = 'mlb';
  const [type, setType] = useState(options[0].type);
  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const [selectedLabel, setSelectedLabel] = useState(options[0].label);
  const [modalVisible, setModalVisible] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [value, setValue] = React.useState('avg');
  // const [customColumns, setCustomColumns] = useState();
  const { propData, columns, loading } = useFetchProps(sport, selectedOption);
  const [ dialogHeaders, setDialogHeaders ] = useState(options[0].headers);
  const [ dialogColumns, setDialogColumns ] = useState(options[0].columns);
  const [sportsbook, setSportsbook] = React.useState('all');
  const [ selectedObject, setSelectedObject ] = useState(options[0]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const filterSportsBook = (event) => {
    setSportsbook(event.target.value);
  };

  const updatePercentageOver = (results, logField, ouField) => {
    const calculateOvers = (value, gameLog) => {
      let lastFiveOver = 0;
      gameLog = (gameLog || []).filter(game => game.date.includes("2022"));
      const lastFive = gameLog.sort((a, b) => parseFloat(b.date) - parseFloat(a.date)).slice(-5);
    
      lastFive.forEach(game => {
          if (game.strikeOuts > value) lastFiveOver++; 
      });
  
      let gamesOver = 0;
      gameLog.forEach(game => {
          if (game.strikeOuts > value) gamesOver++; 
      });
      return {
        gamesOver: gamesOver/gameLog.length,
        lastFiveOver: `${lastFiveOver}/${lastFive.length}`,
      };
    }
  
    results.map(player => {
      let val = (sportsbook === 'fanDuel' || !player.dkOU)  ? player.fdOU : player.dkOU;
      let { gamesOver, lastFiveOver } = calculateOvers(val, player.gameLog)
      player.gamesOver = gamesOver;
      player.lastFiveOver = lastFiveOver;
      return player;
    });
    
    return results;
  };

  const changeBetType = (event) => {
    const opt = options.find(x => x.value === event.target.value);

    setSelectedOption(event.target.value);
    setSelectedLabel(event.target.label);
    setDialogHeaders(opt.headers);
    setDialogColumns(opt.columns);
    setSelectedObject(opt);
    setType(opt.type);
    // setCustomColumns(opt.customColumns)
  };

  return (
    <>
    <CustomizedDialogs
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      data={dialogData}
      propType={selectedLabel}
      type={type}
      sport={sport}
      headers={dialogHeaders}
      columns={dialogColumns}
      useSeasonStats={selectedObject.useSeasonStats}
      xAxis="date"
    />
    <div style={{ textAlign: 'left' }}>
      <h1 className='mainHeader'>MLB Betting Odds</h1>
      <div className={classes.form}>
          <FormControl className={classes.formControl}>
            <FormLabel component="legend">Bet Type</FormLabel>
            <Select
              labelId="bet-type-filter-label"
              id="bet-type-filter"
              value={selectedOption}
              onChange={changeBetType}
              displayEmpty
            >
              {options.map(option => {
                if(option.value) return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                else return <ListSubheader key={option.label}>{option.label}</ListSubheader>
              })}
            </Select>
          </FormControl>
          { selectedOption === 'strikeOuts' && 
            <FormControl className={classes.formControl}>
              <FormLabel component="legend">Sports Book</FormLabel>
              <Select
                labelId="sports-book-label"
                id="sports-book-filter"
                value={sportsbook}
                onChange={filterSportsBook}
                displayEmpty
              >
                <MenuItem value="all">Any</MenuItem>
                <MenuItem value="draftKings">DraftKings</MenuItem>
                <MenuItem value="fanDuel">FanDuel</MenuItem>
              </Select>
            </FormControl>
          }
          { ['hr_futures', 'hits_futures', 'rbis_futures'].includes(selectedOption) &&
            <Tooltip arrow title="This selection dictates what projection system is compared to the OU, shown in the Diff column" placement="top-start">
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">Compare Value</FormLabel>
                <Select
                  labelId="compare-filter-label"
                  id="compare-filter"
                  value={value}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="avg">AVG</MenuItem>
                  <MenuItem value="steamer">Steamer</MenuItem>
                  <MenuItem value="zips">ZiPS</MenuItem>
                  <MenuItem value="atc">ATC</MenuItem>
                  <MenuItem value="fgdc">FanGraphs DC</MenuItem>
                </Select>
              </FormControl>
            </Tooltip>
          }
        </div>
      </div>
      {loading && <LinearProgress />}
      <PropTable
        data={updatePercentageOver(propData)}
        // data={propData.map((player) => {
        //   let custom = [];
        //   // console.log(customColumns)
        //   if(customColumns) {
        //     debugger;
        //     custom.push(customColumns(player));
        //   }
        //   let diff;
        //   if (value === 'avg') diff = ((player['steamer'] + player['zips'] + player['atc'] + player['fgdc']) / 4) - player['O/U'];
        //   else diff =  player[value] - player['O/U'];
        //   return { ...player, diff, ...custom };
        // }).filter(data => {
        //   if(sportsbook === 'fanDuel') {
        //     return !!data.fdOdds;
        //   } else if(sportsbook === 'draftKings') {
        //     return !!data.dkOdds;
        //   }
        //   return true;
        // })}
        type={type}
        sport={sport}
        loading={loading}
        columns={
          [
            { 
              name: "playerName", label: "Name", options: {
              filter: false,
              customBodyRender: (value, tableMeta) => {
                  return (
                    <div style={{display:"flex", alignItems:"center"}}>
                      <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => {
                        let playerData = propData.find(player => player.playerName === tableMeta.rowData[0])
                        setModalVisible(true);
                        setDialogData(playerData);
                      }}>
                        <InfoIcon color="primary"/>
                      </IconButton>
                      {value}
                    </div>
                  )
              },
              setCellProps: () => ({ style: {
                  position: "sticky",
                  left: 0,
                  background: "#ffffffdd",
                  backdropFilter: "blur(2px)",
                  zIndex: 101
              }}),
              setCellHeaderProps: () => ({ style: {
                  position: "sticky",
                  left: 0,
                  top: 0,
                  zIndex: 102
              }}),
            }},
            ...columns.filter(column => {
              let sportsBook = [];
              if(sportsbook === 'fanDuel') sportsBook = ['dkOU', 'dkOdds'];
              if(sportsbook === 'draftKings') sportsBook = ['fdOU', 'fdOdds'];
              return !sportsBook.includes(column.name);
            }),
          ]
        }
        label={selectedLabel}
      />
    </>
  );
});

export default MLBData;
