import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import '../../App.css';
import { Parallax } from 'react-parallax';
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import { Link } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SortIcon from '@material-ui/icons/Sort';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import FilterListIcon from '@material-ui/icons/FilterList';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TableChartIcon from '@material-ui/icons/TableChart';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ListIcon from '@material-ui/icons/List';

const styles = () => ({
    parallax: {
        display: 'flex',        
        justifyContent: 'center',
        alignItems: 'center',
        // background: '#ffffff20',
        // background: 'linear-gradient(#ffffff, #ffffffaa, #ffffff)',
        // padding: '1vh',
    },
    link: {
        color: 'white',
        textDecoration: 'none', 
        fontFamily: 'Audiowide',
        textAlign: 'left',
        margin: '20px',
        fontSize: 'x-large',
    },
    icon: { verticalAlign: 'sub' },
    topDiv: {
        textAlign: 'left',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',  
        padding: '10px',
    },
    flexDiv: {
      display: 'flex',
      flexWrap: 'wrap',
    //   padding: '5vh',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      background: '#ffffff88',
      backdropFilter: 'blur(5px)',
      boxShadow: '2px 2px 4px black',
    },
    flexGrid: {
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
        // padding: '5vh',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        // background: '#ffffffbb',
        background: 'linear-gradient(#ffffff, #ffffffaa, #ffffff)',
        backdropFilter: 'blur(2px)',
        boxShadow: '2px 2px 4px black',
    },
    blue: {
        background: 'linear-gradient(#ffffff, #51ade5aa 25%, #51ade5)',
        color: 'white',
    },
    white: {
        background: 'linear-gradient(#51ade5, #ffffffbc 15% 75%, #ffffff 95%)'
    },
    mainHeader: {
        fontFamily:'Audiowide',
        color: '#264d66',
        maxWidth:'100%',
        overflowWrap: 'break-word',
        textShadow: '2px 2px 2px #5defa5bb',
    },
    blueHeader: {
        fontFamily:'Chivo',
        color: 'white',
        maxWidth:'100%',
        overflowWrap: 'break-word',
        textShadow: '2px 2px 1px rgba(0,0,0,0.7)',
    },
    whiteHeader: {
        fontFamily:'Chivo',
        color: '#51ade5',
        maxWidth:'100%',
        overflowWrap: 'break-word',
        textShadow: '2px 2px 2px white',
    },
    footer: {
        background: 'white',
        height: '5vh',
        width: '100%',
    },
    spacer: {
        background: 'white',
        height: '1vh',
        width: '100%',
    },
    spacerShadow: {
        background: 'white',
        height: '1vh',
        width: '100%',
        boxShadow: '0px 2px 4px 4px',
    },
    icons: {
        color: '#5defa5', 
        verticalAlign: 'text-top',
        filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.7))',
    },
    itemGrid: {
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr) 0fr',
        alignItems: 'center',
        columnGap: '1vh',
    },
    itemGridLeft: {
        display: 'grid',
        gridTemplateColumns: '0fr minmax(0, 1fr)',
        alignItems: 'center',
        columnGap: '1vh',
    },
    '@media screen and (max-width: 960px)': {
        flexGrid: {
            gridTemplateColumns: '1fr',
        },
    },
});

const football = "/images/football.jpg",
    basketball = "/images/basketball.jpeg",
    money = "/images/money.jpg",
    whiteLines = "/images/bg.jpg";

const AboutPage = withStyles(styles)((props) => {
    const { classes } = props;
    const mobile = !useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <div>
           <Parallax bgImage={whiteLines} strength={-400}>
                <div className={classes.parallax} style={{ 
                    background: 'linear-gradient(#ffffff20 85%, #ffffff)',
                    minHeight: '50vh' 
                }}>
                    <div className={classes.topDiv}>
                        <img alt="Beat The Odds: sports betting backed by stats and analytics"
                            style={{ margin:'20px' }}
                            src={`${process.env.PUBLIC_URL}/logos/logo.png`}
                        />
                        <div>
                            {/* <h1 className='mainHeader'>Make Smarter Bets</h1> */}
                            <h1 className={classes.mainHeader}>Beat The Odds</h1>
                            <h2 className={classes.whiteHeader}>Your next winning bet starts here</h2>
                            {/* <h2 className={classes.header}>Be your own handicapper, kiss the juice goodbye</h2> */}
                        </div>
                    </div>
                </div>
            </Parallax>
            <div className={classes.spacer}/>
            <Parallax bgImage={football} strength={400}>
                <div style={{ width: '100%' }}>
                    <div className={classes.parallax} style={{ minHeight: '100vh' }}>
                        <div className={[classes.flexGrid, classes.blue].join(' ')} style={{ minHeight: '100vh', textAlign: 'right' }}>
                            <div style={{ padding: mobile ? '5vh 2vh' : '5vh', boxSizing:'border-box', width: '100%' }}>
                                <h1 className={classes.mainHeader}>Bet Finder</h1>
                                <h2 className={classes.blueHeader}>Quickly sort through bets <SortIcon className={classes.icons}/></h2>
                                <div className={classes.itemGrid}>
                                    <h2 className={classes.blueHeader}>Compare lines with player averages</h2>
                                    <CompareArrowsIcon className={classes.icons}/>
                                </div>
                                <div className={classes.itemGrid}>
                                    <h2 className={classes.blueHeader}>Filter by sportsbook</h2>
                                    <FilterListIcon className={classes.icons}/>
                                </div>
                                <div className={classes.itemGrid}>
                                    <h2 className={classes.blueHeader}>Access opponent stats & game lines</h2>
                                    <MonetizationOnIcon className={classes.icons}/>
                                </div>
                            </div>
                            <div style={{ paddingTop: !mobile && '10vh', padding: mobile && '0vh 2vh', boxSizing:'border-box', }}>
                                <img alt="Compare betting lines, stats, and sportsbooks on your way to making winning bets!"
                                    style={{ maxWidth: mobile && '100%',  maxHeight: '85vh' }}
                                    src={`${process.env.PUBLIC_URL}/images/laptop_bto.png`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>
            <div style={{ background: '#51ade5'}} className={classes.spacer}/>
            <Parallax bgImage={basketball} strength={-400}>
                <div className={classes.parallax} style={{ minHeight: '100vh' }}>
                    <div className={[classes.flexGrid, classes.white].join(' ')} style={{ minHeight: '100vh', textAlign: 'left' }}>
                        {!mobile && 
                            <div style={{ padding: '5vh' }}>
                                <img alt="Analyze detailed player info and stats to find profitable bets"
                                    style={{ maxWidth: '100%', maxHeight: '85vh' }}
                                    src={`${process.env.PUBLIC_URL}/images/tablet_bto.png`}
                                />
                            </div>
                        }
                        <div style={{ padding: mobile ? '5vh 2vh' : '5vh', boxSizing:'border-box', weight: '100%' }}>
                            <h1 className={classes.mainHeader}>In Depth Player Info</h1>
                            <div className={classes.itemGridLeft}>
                                <TableChartIcon className={classes.icons}/>
                                <h2 className={classes.whiteHeader}>Quick & easy access to relevant stats</h2>
                            </div>
                            <div className={classes.itemGridLeft}>
                                <InsertChartIcon className={classes.icons}/>
                                <h2 className={classes.whiteHeader}>Easy to read charts & tables</h2>
                            </div>
                            <div className={classes.itemGridLeft}>
                                <CompareArrowsIcon className={classes.icons}/>
                                <h2 className={classes.whiteHeader}>Compare odds from FanDuel & Draftkings (more to come)</h2>
                            </div>
                            <div className={classes.itemGridLeft}>
                                <ListIcon className={classes.icons}/>
                                <h2 className={classes.whiteHeader}>Opponent lineups & stats</h2>
                            </div>
                        </div>
                        {mobile && 
                            <div style={{ height: '320px', padding: '5vh' }}>
                                <img alt="Mobile friendly betting tools, enabling you to analyze detailed player info and stats to find profitable bets on the fly"
                                    style={{ maxWidth: '100%' }}
                                    src={`${process.env.PUBLIC_URL}/images/tablet_bto.png`}
                                />
                            </div>
                        }
                    </div>
                </div>
            </Parallax>
            <div className={classes.spacerShadow}/>
            <Parallax
                bgImage={money}
                bgImageStyle={{opacity:0.25}}
                strength={500}
                renderLayer={(percentage) => (
                    <div>
                        <div
                            style={{
                                position: "absolute",
                                background: `rgba(81, 173, 229, ${percentage})`,
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%,-50%)",
                                width: `${percentage * 175}%`,
                                height: `${percentage * 175}%`,
                                backgdropFilter: 'blur(4px)',
                            }}
                        />
                    </div>
                )}
            >
                <div className={classes.parallax} style={{ minHeight: '50vh' }}>
                    <div>
                        <h1 className={classes.mainHeader}>Get Started</h1>
                        <div style={{filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.7))'}}>
                            <Link className={classes.link} to="/nfl">
                                <SportsFootballIcon className={classes.icon}/> NFL
                            </Link>
                            <Link className={classes.link} to="/nba">
                                <SportsBasketballIcon className={classes.icon}/> NBA
                            </Link>
                            <Link className={classes.link} to="/mlb">
                                <SportsBaseballIcon className={classes.icon}/> MLB
                            </Link>
                        </div>
                    </div>
                </div>
            </Parallax>
        </div>
    );
});

export default AboutPage;
