import  React, { useState } from 'react';
import '../../App.css';
import PropTable from '../table/index';
import { useFetchProps } from "../../store/props";
import InfoIcon from '@material-ui/icons/Info';
import CustomizedDialogs from "../dialog/index";
import { IconButton } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = () => ({
  form: {
    zIndex: 11,
    margin: '40px',
  },
  formControl: {
    minWidth: '200px',
    padding: '10px',
  },
});

const teamRanksMap = {
  pts: [
    { label: 'PPG', rank: 'PPGRank', value: 'PPG' },
    { label: 'FG %', rank: 'FGPercentageRank', value: 'FGPercentage' },
    { label: 'FG Made', rank: 'FGMRank', value: 'FGM' },
    { label: 'FG Attempts', rank: 'FGARank', value: 'FGA' },
    { label: '3PT %', rank: '3FGPercentageRank', value: '3FGPercentage' },
    { label: '3PT Made', rank: '3FGMRank', value: '3FGM' },
    { label: '3PT Attempts', rank: '3FGARank', value: '3FGA' },
  ],
  reb: [
    { label: 'RPG', rank: 'rpgRank', value: 'rpg' },
    { label: 'REB', rank: 'rebRank', value: 'reb' },
    { label: 'DEF REB', rank: 'dRebRank', value: 'dReb' },
    { label: 'OFF REB', rank: 'oRebRank', value: 'oReb' },
  ],
  ast: [
    { label: 'APG', rank: 'apgRank', value: 'apg' },
    { label: 'A/TO', rank: 'aPerTORank', value: 'aPerTO' },
  ],
  pt3m: [
    { label: '3PT Made', rank: '3FGMRank', value: '3FGM' },
    { label: '3PT Att.', rank: '3FGARank', value: '3FGA' },
    { label: '3PT%', rank: '3FGPercentageRank', value: '3FGPercentage' },
    { label: 'PPG', rank: 'PPGRank', value: 'PPG' },
    { label: 'FG %', rank: 'FGPercentageRank', value: 'FGPercentage' },
    { label: 'FG Made', rank: 'FGMRank', value: 'FGM' },
  ],
  pra: [
    { label: 'PPG', rank: 'PPGRank', value: 'PPG' },
    { label: 'FG %', rank: 'FGPercentageRank', value: 'FGPercentage' },
    { label: 'FG Made', rank: 'FGMRank', value: 'FGM' },
    { label: 'FG Attempts', rank: 'FGARank', value: 'FGA' },
    { label: '3PT %', rank: '3FGPercentageRank', value: '3FGPercentage' },
    { label: '3PT Made', rank: '3FGMRank', value: '3FGM' },
    { label: '3PT Attempts', rank: '3FGARank', value: '3FGA' },
  ],
};

const posRanks = [
  { label: 'PPG', rank: 'ptsRank', value: 'pts' },
  { label: 'REB', rank: 'rebRank', value: 'reb' },
  { label: 'AST', rank: 'astRank', value: 'ast' },
  { label: '3PTM', rank: 'pt3mRank', value: 'pt3m' },
];

const options = [
  { value: 'pts', label: 'Points', maxSliderValue: 50 },
  { value: 'reb', label: 'Rebounds', maxSliderValue: 25},
  { value: 'ast', label: 'Assists', maxSliderValue: 25},
  { value: 'pt3m', label: '3PT Made', maxSliderValue: 20},
  { value: 'pra', label: 'PRA', maxSliderValue: 80},
];

const NBAData = withStyles(styles)((props) => {
  const { classes } = props;
  const sport = 'nba';
  const [selectedOption, setSelectedOption] = useState('pts');
  const [selectedLabel, setSelectedLabel] = useState('Points');
  const previousSelected = false;//[previousSelected, setPreviousSelected] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [value, setValue] = React.useState('all');
  const [teamRanks, setTeamRanks] = useState(teamRanksMap.pts);
  const [ selectedObject, setSelectedObject ] = useState(options[0]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const changeBetType = (event) => {
    const opt = options.find(x => x.value === event.target.value);
    setSelectedOption(event.target.value);
    setSelectedLabel(opt.label);
    setTeamRanks(teamRanksMap[event.target.value])
    setSelectedObject(opt);
  };

  const { propData, columns, loading } = useFetchProps(sport, selectedOption, previousSelected);

  return (
    <>
    <CustomizedDialogs
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      data={dialogData}
      propType={selectedLabel}
      type={selectedOption}
      teamRanks={teamRanks}
      meta={posRanks}
      sport={sport}
      maxSliderValue={selectedObject.maxSliderValue}
      xAxis="date"
    />
    <h1 className='mainHeader'>NBA Betting Odds</h1>
    <div style={{ textAlign: 'left' }}>
      <div className={classes.form}>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend">Bet Type</FormLabel>
          <Select
            labelId="bet-type-filter-label"
            id="bet-type-filter"
            value={selectedOption}
            onChange={changeBetType}
            displayEmpty
          >
            {options.map(option => <MenuItem name={option.label} key={option.value} value={option.value}>{option.label}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend">Sports Book</FormLabel>
            <Select
              labelId="sports-book-label"
              id="sports-book-filter"
              value={value}
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem value="all">Any</MenuItem>
              <MenuItem value="draftKings">DraftKings</MenuItem>
              <MenuItem value="fanDuel">FanDuel</MenuItem>
            </Select>
        </FormControl>
        {/* <input
          type="checkbox"
          label="Previous Results"
          value={previousSelected}
          onChange={(e) => {
            setPreviousSelected(e.target.checked);
          }}
        /> */}
      </div>
    </div>
    <div style={{
      margin: 10
    }}>
      {loading && <LinearProgress />}
      <PropTable
        data={propData.filter(data => {
          if(value === 'fanDuel') {
            return !!data.fdOdds;
          } else if(value === 'draftKings') {
            return !!data.dkOdds;
          }
          return true;
        })}
        type={selectedOption}
        sport={sport}
        label={selectedLabel}
        loading={loading}
        columns={
          [
            { 
              name: "playerName", label: "Name", options: {
              filter: false,
              customBodyRender: (value, tableMeta) => {
                  return (
                    <div style={{display:"flex", alignItems:"center"}}>
                      <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => {
                        let playerData = propData.find(player => player.playerName === tableMeta.rowData[0])
                        setModalVisible(true);
                        setDialogData(playerData);
                      }}>
                        <InfoIcon style={{color:"#51ade5"}}/>
                      </IconButton>
                      {value}
                    </div>
                  )
              },
              setCellProps: () => ({ style: {
                  position: "sticky",
                  left: 0,
                  background: "#ffffffdd",
                  backdropFilter: "blur(2px)",
                  zIndex: 101
              }}),
              setCellHeaderProps: () => ({ style: {
                  position: "sticky",
                  left: 0,
                  top: 0,
                  zIndex: 102
              }}),
          }},
            ...(previousSelected ? columns : columns.filter(column => {
              let sportsBook = [];
              if(value === 'fanDuel') sportsBook = ['O/U', 'dkOdds'];
              if(value === 'draftKings') sportsBook = ['fdOU', 'fdOdds'];
              return !['lastGRes', 'lasGPercentDiff', 'lasGDiff', ...sportsBook].includes(column.name);
            })),
          ]}
      />
    </div>
    </>
  );
});

export default NBAData;
