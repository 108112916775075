import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Page from './Page';
import * as serviceWorker from './serviceWorker';
import { Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
import './App.css';
import { Route, Switch } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import NFLData from "./component/nfl/data";
import NFLPicks from "./component/nfl/picks";
import NBAData from "./component/nba/data";
import MLBData from "./component/mlb/data";
import HomePage from "./component/home/index";
import AboutPage from "./component/home/about";
import Landing from "./component/home/landing";
import PrivacyPolicy from "./component/privacy/privacyPolicy.js";
import BlogPost from "./component/blog/post";
import Blog from "./component/blog/blog";
import ScrollToTop from "./ScrollToTop";
var history = createBrowserHistory();

history.listen((location) => {
  window.ga('set', 'page', location.pathname + location.search);
  window.ga('send', 'pageview');
});


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#51ade5",
    },
    secondary: {
      main: '#356e91',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router history={history}> 
      <ScrollToTop />
      <Switch>
          <Route path="/" exact
            render={(props) => (
              <Page title="Beat The Odds" bgColor="white" padding="0px">
                <HomePage {...props} />
              </Page>
            )}
          />
          <Route path="/about" exact
            render={(props) => (
              <Page title="Beat The Odds - About">
                <AboutPage {...props} />
              </Page>
            )}
          />
          <Route path="/nfl" exact
            render={(props) => (
              <Page title="Beat The Odds - NFL Bets">
                <NFLData {...props} />
              </Page>
            )}
          />
          <Route path="/nfl-picks" exact
            render={(props) => (
              <Page 
                title="Beat The Odds - NFL Picks"
                descr="Discover and analyze NFL bettings odds all in one place! Compare different sportsbooks - get the best lines from DraftKings vs FanDuel to find the best bets available."
              >
                <NFLPicks {...props} />
              </Page>
            )}
          />
          <Route path="/nba" exact
            render={(props) => (
              <Page 
                title="Beat The Odds - NBA Bets"
                descr="Discover and analyze NBA bettings odds all in one place! Compare different sportsbooks - get the best lines from DraftKings vs FanDuel to find the best bets available."
              >
                <NBAData {...props} />
              </Page>
            )}
          />
          <Route path="/mlb" exact
            render={(props) => (
              <Page 
                title="Beat The Odds - MLB Bets"
                descr="Discover and analyze MLB bettings odds all in one place! Compare different sportsbooks - get the best lines from DraftKings vs FanDuel to find the best bets available."
              >
                <MLBData {...props} />
              </Page>
            )}        
          />
          <Route path="/privacy" exact
            render={(props) => (
              <Page title="Beat The Odds - Privacy Policy">
                <PrivacyPolicy {...props} />
              </Page>
            )}        
          />
          <Route path="/landing" exact
            render={(props) => (
              <Page title="Beat The Odds" bgColor="white">
                <Landing {...props} />
              </Page>
            )}        
          />
          <Route path="/blog" exact
            render={(props) => (
              <Page title="Beat The Odds - Blog">
                <Blog {...props} />
              </Page>
            )}        
          />
          <Route path="/blog/:id"
            render={(props) => (
              <Page title="Beat The Odds - Blog">
                <BlogPost {...props} />
              </Page>
            )}        
          />
      </Switch>    
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
