import React from "react";
import {
    FormGroup,
    FormLabel,
    TextField
} from '@material-ui/core';
const roundToTens = (value) => {
    return Math.round(value * 10) / 10;
}

const roundToHundreds = (value) => {
    return Math.round(value * 100) / 100;
}

const convertToPercent = (value) => {
    return `${Math.round(value * 1000)/10}%`;
}

const colorize = (value, under, over) => {
    let color = 'black';
    if (value >= over) color = 'green';
    else if (value < under) color = 'red';

    return (
        <div style = {{ color }}>
            {value}
        </div>
    );
}

const colorAnim = (value, under, over) => {
    let color = 'black';
    if (parseFloat(value) >= over) color = 'green';
    else if (parseFloat(value) < under) color = 'red';

    return (
        <div className="animTable" style = {{ color }}>
            {value}
        </div>
    );
}

const sortOdds = (order) => (a, b) => {
    return (a.data.over < b.data.over ? -1: 1 ) * (order === 'desc' ? 1 : -1);
}

const renderOdds = (value) => {
    return value && (
        <div>
            {colorize(value.over, -110, 100)}
            {colorize(value.under, -110, 100)}
        </div>
    );
}

// const renderRadar = (value) => {
//     const meta = {
//         kRate_rank: { label: 'K%', stat: 'kRate' },
//         kPerGame_rank: { label: 'K/G', stat: 'kPerGame' },
//         bbRate_rank: { label: 'BB%', stat: 'bbRate' },
//         runsPerGame_rank: { label: 'R/G', stat: 'runsPerGame' },
//         avg_rank: { label: 'AVG', stat: 'avg' },
//         ops_rank: { label: 'OPS', stat: 'ops' },
//         abPerHR_rank: { label: 'AB/HR', stat: 'atBatsPerHomeRun' },
//     }

//     if (value) {
//         return (
//             <div style={{height: '48px'}}>
//                 <Radar data={value} meta={meta} mini={true}/>
//             </div>
//         );
//     }
// }

// const renderDiff = (value, tableMeta, a, b) => {
//     const { rowData } = tableMeta;
//     return rowData[1] - (rowData[2] + rowData[3])/2
// }

const customFilter = (label) => {
    return {
        filterType: 'custom',        
        customFilterListOptions: {
            render: v => {
            if (v[0]) {
                return `Min ${label}: ${v[0]}`;
            } else if (v[1]) {
                return `Max ${label}: ${v[1]}`;
            }
            return [];
            },
            update: (filterList, filterPos, index) => {

            if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
                filterList[index] = [];
            }

            return filterList;
            },
        },
        filterOptions: {
            names: [],
            logic(age, filters) {
                if (filters[0] && filters[1]) {
                    return age < filters[0] || age > filters[1];
                } else if (filters[0]) {
                    return age < filters[0];
                } else if (filters[1]) {
                    return age > filters[1];
                }
                return false;
            },
            display: (filterList, onChange, index, column) => (
            <div>
                <FormLabel>{label}</FormLabel>
                <FormGroup row>
                <TextField
                    label='min'
                    value={filterList[index][0] || ''}
                    onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                    label='max'
                    value={filterList[index][1] || ''}
                    onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                />
                </FormGroup>
            </div>
            )
        }
    }
}

const commonColumnsPre = [
    // { name: "playerName", label: "Name", options: { filter: false }},
    // { name: "oppRank", label: "Opp Rank", options: { filter: false }},
    // { name: "opp", label: "Opp", options: { filterType: 'multiselect' }},
    { name: 'team', label: 'Team', options: { filterType: 'multiselect', customBodyRender: (value) => value && value.teamAbbrev }},
    { name: 'opp', label: 'Opp', options: { filterType: 'multiselect', customBodyRender: (value) => value && value.teamAbbrev }},
    { name: "dkOU", label: "DK OU" },
    { name: "fdOU", label: "FD OU" },
    // { name: "O/U", label: "OU"},
    // { name: "K/GS - O/U", label: "Diff", options: { customBodyRender: (value) => colorAnim(roundToTens(value), -1.5, 1.5), ...customFilter('% Diff') }},
    // { name: "proj+-", label: "Proj. +-", options: { customBodyRender: roundToTens }},
    // { name: "projPercentDiff", label: "Proj. % Diff", options: { customBodyRender: convertToPercent, ...customFilter('Proj % Diff') }},
    // { name: "lasGPercentDiff", label: "Last Game %", options: { customBodyRender: convertToPercent }},
    // { name: "lastGRes", label: "Last G", options: { customBodyRender: roundToTens }},
]

const commonColumnsPost = [
    // { name: "dvpRank", label: "DVP Rank" },
    // { name: "score", label: "BetScore" },
    { name:  "gamesOver", label: "Games Over", options: { customBodyRender: convertToPercent }},
    { name:  "lastFiveOver", label: "Last 5 Over"},
    { name: "dkOdds", label: "DK Odds", options: { customBodyRender: renderOdds, sortCompare: sortOdds }},
    { name: "fdOdds", label: "FD Odds", options: { customBodyRender: (value, tableMeta) => renderOdds(value)}},
    // { name: "fdOverOdds", label: "FD Over Odds" },
    // { name: "fdUnderOdds", label: "FD Under Odds" },
    // { name:  "overOdds", label: "DK Over Odds", options: { customBodyRender: (value) => colorize(value, 1.9) }},
    // { name: "underOdds", label: "DK Under Odds", options: { customBodyRender: (value) => colorize(value, 1.9) }}
]

const projections = [
    { name: "steamer", label: "Steamer" },
    { name: "zips", label: "ZiPS" },
    { name: "atc", label: "ATC"},
    { name: "fgdc", label: "FanGraphs DC"},
]

// const renderAsRank = (value, stat, rank) => {
//     return (<RankCircle hideLabel={true} rank={value.stats[rank]} teams={32} label='K%' value={convertToPercent(value.stats[stat])} />);
// }

export const strikeOuts = [
    { name: "gameLog", label: "Foo", options: { display: 'excluded', customBodyRender: (value, tableMeta, x) => {
        // console.log(value, tableMeta, x)
    }}},
    { name: "PH", label: "PH" },
    // { name: 'matchup', label: 'Matchup', options: { filterType: 'multiselect' }},
    // { name: 'game', label: 'Game Time', options: { filterType: 'multiselect', customBodyRender: (game) => game && new Date(game.date).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})}},
    ...commonColumnsPre,
    { name: "K/G", label: "K/GS", options: { customBodyRender: roundToHundreds }},
    { name: "K/GS - O/U", label: "Diff", options: { customBodyRender: (value) => colorAnim(roundToTens(value), -1, 1), ...customFilter('Diff') }},
    { name: "IP/GS - IP Needed", label: "IP Needed +-", options: { customBodyRender: (value) => colorAnim(roundToTens(value), -1, 1), ...customFilter('Diff') }},
    { name: "Batters Needed +-", label: "BF Needed +-", options: { customBodyRender: (value) => colorAnim(roundToTens(value), -1, 1), ...customFilter('Diff') }},
    { name: "era", label: "ERA", options: { customBodyRender: roundToHundreds }},
    { name: "K9", label: "K/9", options: { customBodyRender: roundToHundreds }},
    { name: "KRate", label: "K Rate", options: { customBodyRender: roundToHundreds }},
    { name: "oppKPerGame", label: "Opp K/G", options: { customBodyRender: roundToHundreds }},
    { name: "oppKRate", label: "Opp K%", options: { customBodyRender: convertToPercent }},
    // { name: 'battersNeededADV', label: 'BN' },
    // { name: 'IP Needed', label: 'IP N' }, 
    // { name: "opp", label: "Opp K Rate", options: { customBodyRender: (value) => renderAsRank(value, 'kRate', 'kRate_rank') }},
    // { name: "opp", label: "Opp", options: { customBodyRender: renderRadar }},
    // { name: "fdOU", label: "Games Over FD", options: { customBodyRender: (value, tableMeta, x) => {
    //     // console.log(value, tableMeta, x)
    //     let over = 0;
    //     tableMeta.rowData[1].forEach(game => {
    //         if (game.strikeOuts > value) over++; 
    //     });
    //     return convertToPercent(over / tableMeta.rowData[1].length);
    // }}},
    // { name: "fdOU", label: "Last5 Over FD", options: { customBodyRender: (value, tableMeta, x) => {
    //     // console.log(value, tableMeta, x)
    //     const gameLog = tableMeta.rowData[1];
    //     let over = 0;
    //     const lastFive = gameLog.filter(game => game.date.includes("2021"))
    //         .sort((a, b) => parseFloat(b.date) - parseFloat(a.date))
    //         .slice(-5);

    //     lastFive.forEach(game => {
    //         if (game.strikeOuts > value) ++over; 
    //     });
    //     return `${over}/${lastFive.length}`;
    // }}},
    ...commonColumnsPost,
]

export const hr_futures = [
    { name: "O/U", label: "DK OU"},
    ...projections,
    { name: "diff", label: "Diff" },
    { name: "hr", label: "2020", options: { customBodyRender: roundToTens }},
    { name: "dkOdds", label: "DK Odds", options: { customBodyRender: (value, tableMeta) => renderOdds(value)}},
]

export const hits_futures = [
    { name: "O/U", label: "DK OU"},
    ...projections,
    { name: "diff", label: "Diff" },
    { name: "h", label: "2020", options: { customBodyRender: roundToTens }},
    { name: "dkOdds", label: "DK Odds", options: { customBodyRender: (value, tableMeta) => renderOdds(value)}},
]

export const rbis_futures = [
    { name: "O/U", label: "DK OU"},
    ...projections,
    { name: "diff", label: "Diff" },
    { name: "rbi", label: "2020", options: { customBodyRender: roundToTens }},
    { name: "dkOdds", label: "DK Odds", options: { customBodyRender: (value, tableMeta) => renderOdds(value)}},
]