import React from "react";
import MUIDataTable from "mui-datatables";
// import TableRow from "@material-ui/core/TableRow";
// import TableCell from "@material-ui/core/TableCell";
// import TableFooter from "@material-ui/core/TableFooter";

// const PropTableOld = forwardRef(function PropTable(props, ref) {
//   const { data, columns, loading } = props;   

//   // const tableRef = React.createRef();
//   const options = {
//     responsive:'standard',
//     selectableRows:'none',
//     expandableRowsHeader: false,
//     sort: true,
//     fixedHeader: true,
//     tableBodyMaxHeight: '70vh',
//     pagination: true,
//     download: false,
//     print: false,
//     rowsPerPageOptions: [],
//     rowsPerPage: 20,
//     // customFooter: (count) => {
//     //   return (
//     //     <TableFooter>
//     //     <TableRow>
//     //       <TableCell style={{ textAlign: 'right' }}>
//     //         1-{count} of {count} 
//     //       </TableCell>
//     //     </TableRow>
//     //   </TableFooter>
//     //   );
//     // },
//   }

//   return(
//       <div style={{ display: loading ? 'none': '', maxWidth: '100%' }}>
//         { data && 
//           <MUIDataTable
//             ref={ref}
//             title=""
//             data={data}
//             columns={columns}
//             options={options}
//           />
//         }
//       </div>
//     );
// });

class PropTable extends React.Component {
  constructor(props) {
    super(props);
    const { data, columns, loading } = props;   
    const options = {
      responsive:'standard',
      selectableRows:'none',
      expandableRowsHeader: false,
      sort: true,
      fixedHeader: true,
      tableBodyMaxHeight: '70vh',
      pagination: true,
      download: false,
      print: false,
      rowsPerPageOptions: [],
      rowsPerPage: 20,
    }

    this.tableRef = React.createRef();
    this.state = {
        data,
        columns,
        loading,
        options,
    }
  }

  render() {
    const { data, columns, loading, options } = this.state;

    return(
      <div style={{ display: loading ? 'none': '', maxWidth: '100%' }}>
        { data && 
          <MUIDataTable
            ref={this.tableRef}
            title=""
            data={data}
            columns={columns}
            options={options}
          />
        }
      </div>
    );
  }
}

export default PropTable;