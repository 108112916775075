import '../../css/style.css';
import React, { useState }from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { slide as Menu } from 'react-burger-menu';
import { Link } from "react-router-dom";
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import AppsIcon from '@material-ui/icons/Apps';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: 'Audiowide',
  },
  accordion: {
    backgroundColor: '#51ade5',
  },
  appBar: {
    backgroundColor: '#ffffff00'
  },
  root: {
    flexGrow: 1,
    color: 'white',
    display: 'grid',
  },
  link: {
    color: 'white',
    textDecoration: 'none', 
    fontFamily: 'Audiowide',
    textAlign: 'left',
    margin: '10px',
  },
  blogLink: {
    color: 'white',
    textDecoration: 'none', 
    fontFamily: 'Audiowide',
    textAlign: 'left',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: { flexGrow: 1 },
  icon: { verticalAlign: 'bottom' },
  dialog: { border: 'none' },
  feedback: {
    marginLeft: 'auto',
    marginRight: '40px',
    color: 'white',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
  dialogTitle: {
    backgroundColor: '#51ade5',
    color: 'white',
    fontFamily: 'Audiowide',
    fontSize: 'x-large',
  }
}));


const MainAppBar = () => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const closeMenu = () => {
    setMenuOpen(false);
  }

  const handleClose = () => {
    setModalVisible(false);
  };
  
  return (
    <div className={classes.root}>
        <Menu disableAutoFocus right isOpen={menuOpen} onStateChange={(state) => setMenuOpen(state.isOpen)}>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}><SportsFootballIcon className={classes.icon}/> NFL</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Link onClick={closeMenu} className={classes.link} to="/nfl">
                  <AppsIcon className={classes.icon}/> BetFinder
                </Link>
                {/* <Link onClick={closeMenu} className={classes.link} to="/nfl-picks">
                  <div>
                    <LibraryAddCheckIcon className={classes.icon}/> Picks
                  </div>
                </Link> */}
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}><SportsBasketballIcon className={classes.icon}/> NBA</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Link onClick={closeMenu} className={classes.link} to="/nba">
                  <AppsIcon className={classes.icon}/> BetFinder
                </Link>
                {/* <Link onClick={closeMenu} className={classes.link} to="/nba-picks">
                  <div>
                    <LibraryAddCheckIcon className={classes.icon}/> Picks
                  </div>
                </Link> */}
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}><SportsBaseballIcon className={classes.icon}/> MLB</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Link onClick={closeMenu} className={classes.link} to="/mlb">
                <AppsIcon className={classes.icon}/> BetFinder
              </Link>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <Link onClick={closeMenu} className={classes.blogLink} to="/blog">
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
              >
                <Typography className={classes.heading}><LocalLibraryIcon className={classes.icon}/> Blog</Typography>
              </AccordionSummary>
            </Link>
          </Accordion>
        </Menu>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar 
          className="animatedBG"
          style = {{ minHeight: '64px'
          }}
        >
          <Link to="/">
            <img alt="Beat The Odds: sports betting using stats and analytics"
                style={{width:'50px'}}
                src={`${process.env.PUBLIC_URL}/logos/logo_w.png`}
            />
          </Link>
          <IconButton className={classes.feedback} onClick={()=>setModalVisible(true)}>
            <FeedbackIcon/>
          </IconButton>
          <Dialog className={classes.dialog} maxWidth={'md'} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalVisible}>
            <MuiDialogTitle disableTypography className={classes.dialogTitle}>
                <div>Feedback</div>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <iframe title='Feedback' src="https://docs.google.com/forms/d/e/1FAIpQLSfcX9_u5Oh4I_mCDTWoqla7YH9MFRGvEt5Tqne2InUaowgMeQ/viewform?embedded=true" width={fullScreen ? "" : "640"} height="1275">Loading…</iframe>
          </Dialog>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default MainAppBar;